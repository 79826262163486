
.viewCard {

    p {

        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;

        ion-icon {

            margin-right: 0.5rem;
        }
    }

    .cardImage {

        height: 10rem;
        width: 100%;
        background-position: top center;
        background-size: cover;
    }
}

.viewCardModal {

    p {

        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;

        ion-icon {

            margin-right: 0.5rem;
        }
    }

    .cardImage {

        height: 10rem;
        width: 100%;
        background-position: top center;
        background-size: cover;
    }
}

.viewCardContainerModal {

    // height: 90vh;
    height: fit-content;
    // border:2px solid red;
    width: 100%;
    // overflow: scroll;
    .content {
        background-color: none;
    }

    ion-list{
        overflow-y: scroll;
        height: fit-content;
    }
}

.space {
    // background-color: red;;
    // height: 35px;
}