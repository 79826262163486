.PostRQPaymentPage {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 36px 0 36px;

  overflow-y: auto;

  &::-webkit-scrollbar {
      display: block;
      width: 14px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
      background: #e7e7e7;
      border-radius: 20px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
      background: rgb(126, 126, 126);
      border-radius: 20px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
  }

    .itemsTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      margin-bottom: 2px;
      font-family: 'Poppins-SemiBold';

      .title {
          color: var(--mrq-add-items-secondary-text);
          font-size: 16px;
          font-weight: bold;
      }

      section {
          color: var(--mrq-add-items-accent);
          font-size: 16px;
          font-weight: bold;
      }
    }
      
      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 12px 0 0 0;
      }

      .elementContainer {
        display: flex;
        flex-direction: column;
        width: 100%;


        p {
          font-family: 'Poppins-SemiBold';
          font-size: 14px;
          color: var(--kargoroo-payment-page-text);
          margin-left: 3px;
          // margin-bottom: 4px;
        }

        .error {
          color: red;
          font-size: 13px;
          font-family: 'Poppins-Regular';
          margin-left: 3px;
          margin-top: -2px;
        }
        
      }

      .cardElement {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        margin-bottom: 6px;
        padding: 11px 12px 12px 12px;
        border: 1px solid #dbdbdb;
        font-size: 14px;
        background: white;
      }

      .form-row {
        display: flex;
        flex-direction: row;
        
        .expiry {
          margin-right: 3px;
        }

        .cvc {
          margin-left: 3px;
        }

      }

      .newPreauthContainer {
        padding: 12px;
        p {
          span {
            font-weight: bold;
          }
        }
      }
}

.postRQ_spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 12px;

  text-align: center;

  ion-spinner {
    width: 48px;
    height: 48px;
    color: var(--mrq-green-dark);
  }
}