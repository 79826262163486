.enlargeImageModal {
  --height: 100%;
  --width: 100%;

  .modalContent {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: linear-gradient(to bottom, #e7e7e7 0%, #c9c9c9 100%);

    .imageWrapper {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%;

      .controls {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 1500;
      
        .btn {
          height: 40px;
          width: 40px;
          margin: 4px;
          font-size: 32px;
          background: var(--mrq-button-main-background);
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 50%;
          border: 1px solid red;
          border-color: var(--mrq-green-dark);

          &.close {
            margin-bottom: 72px;
          }

          &:active {
            background: var(--mrq-button-main-background-active);
          }
        }
        transform: translate3d(0,0,0);
        -webkit-transform: translate3d(0,0,0);
      }

      .react-transform-wrapper {
        width: 100%;
        height: 100%;
        position: relative
      }

      

      .enlargedPhoto {
      }

    }

  }
}