.information-popup {
    --height: fit-content;
    background: rgba(0, 0, 0, 0.834);;

    .grid-container{
        display: flex;
        height: 100%;
        width: fit-content !important;
        // background-color: transparent !important;

            .slide-content {
                // margin: 0 0;
                color: var(--ion-color-primary);
                border: 2px solid rgb(228, 228, 228);
                border-radius: 15px;

                img{
                    display: block;
                    margin: 0 auto;
                }
                h1{
                    text-align: center;
                }
                }

                .slide-content p {

                    color: rgb(161, 161, 161);
                }

                ion-checkbox {
                    margin-right : 7px;
                }

                ion-button{
                    margin-top: 0.75rem;
                }
        
    }
}