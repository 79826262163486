.questcontainer {
    margin-left: -7px !important;
    margin-right: -7px !important;
}
.FullQuest .questcontainer .questTitle{
  margin-left: 20px;
  text-align: left;
  font-size: 16px;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 6px;
}
.questreq {
  ion-content {
  // --overflow: hidden !important;
  --background: var(--kargoroo-dark-background-color);
  }
}
.FullQuest {
  text-align: center;
  // height: 100%;
  width: 100%;
  padding: 0px;
}
.enableformdiv{
    background-color: rgb(215, 250, 222);
    width: 130px;
    border: 1px solid rgb(215, 250, 222);
    padding: 50px;
    margin: 0 auto;
    border-radius: 20px;
  }
  .enabletext{
      margin: 2px;
      font-weight: bold;
      font-size: 13px;
  }
  .disableformdiv{
    background-color: lightgrey;
    width: 130px;
    border: 1px solid lightgrey;
    padding: 50px;
    margin: 0 auto;
    border-radius: 20px;
  }
  .disabletext{
    margin: 2px;
    color: grey;
    font-size: 13px;
    font-weight: bold;
}
.disabletext_cs{
  margin: 2px;
  color: grey;
  font-size: 11px;
  font-weight: bold;
}
.questHeaderText {
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.FullQuest .questcontainer{
    color: white;
    background-color: var(--kargoroo-background-color);;
    border-radius: 25px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    font-family: 'Montserrat-Regular';
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.FullQuest .questcontainer .fullInnerContainer{
  color: var(--kargoroo-single-item-quest-text-color);
  background-color: var(--kargoroo-single-item-quest-bg);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 0px;
  padding: 1px;
  position: relative;
  // height: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
}
.questcontainer .questLeft{
  float: left;
}
.questcontainer .bottombuttonSingleItem {
    margin: 10px 9px;
}
.questcontainer .questRight{
  float: right;
  margin-right: 20px;
  margin-top: 16px;
  font-size: 13px;
}
.prevbtn {
  // --background: #e7e6e6;
  width: 100%;
  color: black;
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Montserrat-Regular'
}
.nextbtn {
  // --background: #00b050;
  width: 100%;
  color: white;
  height: 40px;
  padding-right: 5px;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Montserrat-Regular'
}
.questBodyContent .inputField {
  padding-right: 14px;
}
body .ion-activated{
  --background: #E6E6E6 !important;
}
body .ion-activated button{
  --background: #E6E6E6 !important;
}
.questcontainer .questBodyContent{
  margin-top: 10px;
  /*height: calc(100vh - 182px);
  overflow: auto;*/
}
.questreqdisplay{
  height: calc(100vh - 104px);
}
.questHeaderReviewText{
  padding: 20px;
}
.questheadertext{
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 18px;
  font-weight: bold;
}
.singlereviewtotalbg{
  background-color: var(--kargoroo-single-item-quest-modify-bg);
  border-radius: 10px;
  // padding: 8px
}
.singlereviewtotalbtn{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    background: var(--kargoroo-single-quest-ion-item-bg);
    color: white;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
    float: left;
}
.singleTotalpaddingnone{
  padding: 0px;
}
.singlereviewlabel {
  font-size: 12px;
  font-weight: bold;
  color:var(--kargoroo-single-item-quest-info-text-color);
  margin: 0px;
  float: left;
}
.singlereviewtext {
  font-size: 12px;
  font-weight: bold;
  color: var(--kargoroo-single-item-quest-text-color);
  text-align: left;
  margin: 0px;
  text-align: justify;
}
.singlereviewrighttext{
  font-size: 12px;
  font-weight: bold;
  color: var(--kargoroo-single-item-quest-text-color);
  float: right;
  margin: 0px;
  text-align: justify;
}
.percentagetext{
  color: var(--kargoroo-single-item-quest-text-color);
}
.questcardpadding{
  padding: 10px;
}