.delivererFeedContent {
  width: 100%;
  position: fixed;
  z-index: 8;
  bottom: 0px;

  .bottom-drawer {
    position: absolute;
    right: 1px;
    left: 1px;
    bottom: -71vh;
    height: 95vh;
    border-radius: 30px;
    border-color: #00bd00;
    border-width: 2px;
    border-style: solid;
    background-color: var(--kargoroo-card-bg-color);
    //max-height: 0;

    @media (max-height: 685px) {
      height: 90vh;
    }

    @media (max-height: 600px) {
      height: 80vh;
      bottom: -62vh;
    }

    @media (max-height: 525px) {
      height: 70vh;
      bottom: -72vh;
    }

    @media (max-height: 415px) {
      height: 65vh;
      bottom: -76vh;
    }

    .setupDeliveryAccount {
      border: 1px solid rgb(236, 76, 76);
      border-radius: 25px;
      padding: 0 8px 0 8px;
      margin: 0 8px 0 8px;
      height: 28px;
      text-align: center;
      background-color: rgb(255, 236, 236);

      ion-icon {
        position: relative;
        top: 3px;
        font-size: 20px;
      }
      p {
        margin: 0;
        padding: 0px 0 4px 0;
        color: rgb(236, 76, 76);
        font-size: 16px;
        font-family: 'Montserrat-Regular';
        font-weight: bold;
      }
    }
    .setupDeliveryAccount:active {
      background-color: rgb(241, 151, 151);
    }

    .RQFeedCardContent {
      display: flex;
      flex-direction: column;

      .mainContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;

        .rightRQInfo {
          display: flex;
          flex-direction: column;
          min-height: 100%;
          min-width: 80px;
          align-items: flex-end;
          justify-content: space-between;
          padding-top: 0px;

          font-family: 'Montserrat-Regular';

          .viewOnMap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            color: var(--kargoroo-my-req-del-text);

            ion-icon {
              font-size: 24px;
              color: #00bd00;
              padding: 5px;
            }

            p {
              font-size: 14px;
              margin: 0;
              padding: 0;
            }
          }

          .saveComponent {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 5px;

            p {
              margin: 0;
              padding: 0;
            }
          }

          .posted {
            padding-top: 12px;
            padding-bottom: 4px;
            padding-right: 4px;
            text-align: right;
            font-family: 'Montserrat-Regular';
            display: flex;
            p {
              span {
                font-weight: bold;
              }
              color: var(--kargoroo-my-req-del-text);
              font-size: 11px;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .backMenuButton {
    text-align: center;
    margin: 18px 8px 0px 12px;
    padding-top: 6px;
    position: fixed;
    top: 50px;
    background-color: var(--kargoroo-background-color,#00bd00);
    width: 44px;
    height: 44px;
    border-radius: 25px;
    font-size: 32px;
    cursor: pointer;
    ion-icon {
      color: white;
      --ionicon-stroke-width: 38px;
    }
  }

  .rqItems {
    // background-color: purple;
    overflow-y: scroll;
    height: 90%;
    padding-bottom: 35vh;
    text-align: center;
    scrollbar-width: none; /* Firefox */
    ion-card {
      border: 1px solid #048800;
      border-radius: 20px;
      cursor: pointer !important;
    }

    .noRQs {
      margin-top: 20px;
      height: 140px;
    }
  }
  .routeInfo {
    display: flex;
    flex-direction: row;
  }
  .offersubmitted {
    font-weight: bold;
    font-size: 14px;
    .offersubmittext {
      margin:2px;
      color: var(--kargoroo-background-color, #00bd00);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .delivererFeedContent {
    .bottom-drawer{
      width: 550px;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .delivererFeedContent {
    .bottom-drawer{
      width: 550px;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 1601px) {
  .delivererFeedContent {
    .bottom-drawer{
      width: 550px;
      margin: 0 auto;
    }
  }
}
