// Modern RooQuest Pages

:root {
    --mrq-green-light: #62CB91;
    --mrq-green: #10be5f;
    --mrq-green-dark: #06ad51;
    --mrq-red: #e63b3b;
    --mrq-grey-light: #808080;
    --mrq-grey: #6b6b6b;
    --mrq-grey-dark: #505050;

    // --mrq-background: #ebf8f0;
    --mrq-main-background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%);
    --mrq-info-text-p: var(--mrq-green-light);
    --mrq-info-text-h1: black;
    --mrq-button-main-background: linear-gradient(to bottom right, #4cd380, #3ac26e);
    --mrq-button-main-background-active: linear-gradient(to bottom right, #258d35, #2bb35f);
    --mrq-button-main-background-danger: linear-gradient(to bottom right, #ec6666, #ee6060);
    --mrq-button-secondary-background: linear-gradient(to bottom right, #d8d8d8, #d4d4d4);
    --mrq-button-secondary-background-active: linear-gradient(to bottom right, #d1cdcd, #ccc9c9);

    // Add Items
    --mrq-add-items-accent: var(--mrq-green);
    --mrq-add-items-secondary-text: var(--mrq-grey);
    --mrq-add-items-secondary-text-light: var(--mrq-grey-light);

    // Toasts
    --toast-danger: #FF5158;
    --toast-danger-btn: #fd6c71;
    --toast-danger-btn-border: #fc757c;

    --toast-success: #27bb9b;
    --toast-success-btn: #41c5a9;
    --toast-success-btn-border: #4fd1b5;

    --toast-info: #7A6DC8;
    --toast-info-btn: #887bd3;
    --toast-info-btn-border: #9689e0;
}