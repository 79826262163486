.PickItemModal {
    backdrop-filter: blur(3px);

    --min-width: 80%;
    --max-width: 800px;
    --min-height: 80%;
    --height: 85%;
    --width: 100%;
    
    &::part(background) {
        border-radius: 20px;
    }

    &::part(handle) {
        background: var(--mrq-green-light);
        width: 100px;
        height: 6px;
    }

    &::part(content) {
        border-radius: 0px;
        box-shadow: 0px 0px 20px 5px rgba(5, 5, 5, 0.32);
        background-color:white;
    }

    ion-content {
        --background: var(--pick-item-modal-background);
    }

    ::-webkit-scrollbar {
        display: block;
        width: 18px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 20px;
        border: 7px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 20px;
        border: 7px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }

    .PickItemModalContent {

        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        font-family: 'Poppins';

        .header {
            height: 50px;
            margin-bottom: 16px;


            .closeBtn {
                position: absolute;
                top: 6px;
                right: 8px;
                font-size: 32px;
                color: rgb(150, 150, 150);
            }

            .title {
                margin-top: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
    
                p {            
                    margin: 18px 0 8px 0;    
                    font-size: 20px;
                    font-weight: bold;
                    color:var(--pick-item-modal-title-color);
                }
            }
        }
    
        .main {
            display: flex;
            flex-direction: column;
            height: 100px;
            width: 100%;
            flex-grow: 1;
            overflow-y: auto;

            .description {
                text-align: center;
                padding: 12px 32px;
                color: var(--mrq-grey);
                font-size: 15px;

                span {
                    font-size: 14px;
                    font-weight: bold;
                    color: var(--mrq-green);
                }
            }

        
            ion-accordion {
                border-radius: 6px;
                text-align: left;

                ion-item, ion-list {
                    --background: var(--pick-item-modal-ion-item-background);
                }

                ion-list {
                    background: #fcfcfc;
                    border: 1px solid #e7e7e7;
                    border-top: 0;
                    border-radius: 0 0 6px 6px;

                }

                p {
                    margin: 0;
                }
        
                ion-icon {
                    font-size: 18px;
                    color: grey;
                }
        
                ion-item {
                    --border-color: #a3a3a3;
                }
        
                .cost-info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 4px;
                    border-bottom: 2px solid #6b6b6b;
        
        
                    .maininfo {
                        font-size: 13px;
                        color: var(--mrq-add-items-accent);
                        font-weight: bold;
                        margin-bottom: 12px;
                        margin: 0;
                        text-align: center;
                    }
        
                    .subinfo {
                        font-size: 12px;
                        color: var(--mrq-add-items-secondary-text);
                        color: black;
                        font-weight: bold;
                        margin: 0;
                        text-align: center;
        
                    }
        
                }
            }
        
            .infoRQ-item-info {
                display: flex;
                flex-direction: column;
                padding: 6px 2px;
                width: 100%;
        
                .item-title {
                    font-weight: bold;
                    color : var(--pick-item-modal-item-title-color);
                }
        
                .cost {
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-between;
                    font-size: 14px;
                    font-family: 'Poppins-SemiBold';
                    color: #808080;
                    color: var(--mrq-add-items-secondary-text-light);
        
                    .total {
                        font-weight: bold;
                    }
                }
            }
        
            .infoRQ-item-moreinfo {
                // border-bottom: 2px solid #6b6b6b;
        
                .infoRQ-moreinfo-section {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin: 0 8px;
                    padding: 8px;
                    border-bottom: 1px solid #e6e6e6;
        
                    &:first-child {
                        margin-top: 0;
                    }
        
                    &:last-child {
                        border-bottom: none;
                    }
        
                    color: black;
                    
        
                    .infoRQ-sectionTitle {
                        font-size: 14px;
                        font-weight: bold;
                        // font-family: 'Poppins-SemiBold';
                        color: var(--mrq-add-items-secondary-text-light);
                        margin-bottom: 4px;
                    }
        
                    .infoRQ-sectionContent {
                        font-size: 14px;
                        color: --var(kargoroo-text) !important;
                    }
        
                    .itemCost {
                        margin-left: 12px;
                    }
        
                    .photosContainer {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        overflow-x: scroll;
        
                        img {
                            width: 56px;
                            height: 56px;
                            margin-right: 8px;
                            object-fit: cover;
                            border: 1px solid #5f5f5f;
                        }
                    }
                }
            }

            .inputsContainer {
                padding: 0px 32px 12px 32px;


                .inputSection {
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    .question {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-end;
                        color: var(--pick-item-modal-question-color);
                        font-weight: bold;
                        font-size: 16px;

                        p {
                            margin: 0;
                        }

                        .subInfo {
                            font-size: 13px;
                            margin-left: 2px;
                        }

                    }
                }

                .uploadImageSection {
                    width: 100%;
                    margin-top: 3px;
                    margin-bottom: 16px;
                }

                .upload-img-bar {
                    position: relative;
                    background-color: #edf0f5;
                    border: 1px rgb(214, 214, 214) solid;
                    display: flex;
                    flex-direction: row;
                    border-radius: 8px;
                    height: 74px;
                    align-items: center;
                    width: 100%;
        
                    .list-of-images {
                        display: flex;
                        overflow-x: auto;
                        flex-direction: row;
                        height: 100%;
                        padding-right: 64px;
        
                        &::-webkit-scrollbar {
                            display: block;
                            height: 4px;
                        }    
            
                        .upload-img {
                            position: relative;
                            height: 100%;
                            display: flex;
                            flex-shrink: 0;
                            margin-right: 4px;
            
                            img {
                                height: 100%;
                                width: 86px;
                                object-fit: cover;
                                padding: 4px;
                                border-radius: 8px;
                            }
        
                            .removeImg {
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 20px;
                                width: 20px;
                                right: -3px;
                                top: 2px;
                                font-size: 16px;
                                color: white;
                                background-color: var(--mrq-red);
                                cursor: pointer;
                                border-radius: 50px;
                            }
                        }        
                    }               
        
                    .uploadBtn {
                        display: flex;
                        width: 40px;
                        height: 40px;
                        background-color: var(--mrq-green);
                        align-items: center;
                        justify-content: center;
                        border-radius: 8px;
                        
                        position: absolute;
                        right: 8px;
        
                        ion-icon {
                            font-size: 24px;
                            font-weight: bold;
                            color: white;
        
                        }
        
                        &:active {
                            background-color: var(--mrq-green-dark);
                        }
                    }
                } 

                .inputItem {
                    display: flex;
                    flex-direction: row;

                    .shelfPriceType {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        color:var(--pick-item-modal-shelf-price-type-color) ;
                        
                        ion-icon {
                            position: relative;
                            left: 4px;
                            bottom: 1px;
                            font-size: 14px;
                            color: var(--mrq-grey);
                        }
                    }
                }

                input {
                    margin: 12px;
                    text-decoration: none;
                    text-align: center;
                    font-weight: bold;
                    outline: none;
                    border: none;
                    color: var(--mrq-green);
                    font-size: 24px;
                    width: 50px;
                    border-bottom: 2px green solid;
                    border-color: var(--mrq-green);
        
                    margin-right: 12px;
                    background-color: var(--input-container-dark-background);
                }

                .error{
                    border: 1px solid red;
                }

                

                .price {
                    input {
                        width: 124px;
                        text-align: left;
                        padding-left: 8px;
                    }
                }

            }

            .problemWithItem {
                position: relative;
                margin-bottom: 28px;

                ion-item {
                    --border-color: #dfdfdf;
                    color:var(--pick-item-modal-problem-with-item-ion-label-color);
                }

                ion-radio {
                   background-color:var(--pick-item-modal-ion-radio-background,inherit);

                }

                .reason-note {
                    position: relative;

                    textarea {
                        text-decoration: none;
                        text-align: left;
                        outline: none;
                        border: none;
                        color: var(--pick-item-modal-add-comment-color);
                        font-size: 18px;
                        width: 100%;
                        border-bottom: 2px green solid;
                        border-color: var(--mrq-green);
                        background-color: var(--pick-item-modal-add-comment-background);
            
                    }
                }
            }
        }
    
        .footer {
            display: flex;
            flex-direction: column;
            bottom: 12px;
            height: 50px;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-bottom: 18px;

            .notAvailable {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 28px 8px 12px 8px;

                ion-checkbox {
                    margin-right: 8px;
                    --background: #ebebeb;
                    width: 32px;
                    height: 32px;

                }
            }

            .collectItemBtn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: var(--mrq-green);
                color: white;
                border-radius: 50px;
                font-weight: bold;
                width: 70%;
                max-width: 350px;
                height: 50px;


                &:active {
                    background-color: var(--mrq-green-dark);
                }

                ion-spinner {
                    color: black;
                    height: 50px;
                }

                &.loading {
                    background-color: #cfcfcf;
                }
            }
        }
    }
}