.GoogleAddressAutoCompleteContainer {
  .GAAC_input {
    padding-right: 12px;

    ion-textarea {
      height: 70px;
    }
  }
}

.inputField{
  font-size: 14px;
}

.pac-container {
  .pac-item {
    height: 50px;
    font-size: 12px;
  }
  .pac-item-query {
    font-family: 'Montserrat-Regular';
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px 0 0 10px;
  }
  .pac-matched {
    color: rgb(11, 105, 11);
  }
}