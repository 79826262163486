.passwordResetModalContent {
  text-align: center;
  .modalTitle {
    padding-top: 10px;
    padding-bottom: 12px;
  }

  .emailError {
    text-align: left;
    padding-left: 20px;
    position: relative;
    bottom: 8px;
    font-size: 14px;
    color: rgb(255, 66, 66);
  }

  .buttonContainer {
    position: absolute;
    bottom: 8px;
    text-align: center;
    width: 100%;
    ion-button {
      font-family: 'Montserrat-Regular';
      font-weight: bold;
      --border-color: #044800;
      --border-radius: 10px;
      --border-style: solid;
      --border-width: 2px;
      width: 80%;
    }
  }
}
