@import "~animate.css/animate.min.css";

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'),
    url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Medium';
  src: local('Raleway-Medium'),
    url(./fonts/Raleway-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Semibold';
  src: local('Raleway-Semibold'),
    url(./fonts/Raleway-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Raleway-Semibold'),
    url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Semibold';
  src: local('Poppins-Semibold'),
    url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'),
    url(./fonts/Poppins-Light.ttf) format('truetype');
}
/* css for padding at top of app. */
ion-app {
  margin-top: env(safe-area-inset-top);
  /* margin-bottom: env(safe-area-inset-bottom) */
}
ion-content{
  --keyboard-offset: 0 !important;
}
.update-info-popup .alert-wrapper {
  background: white;
}