.overlayContainer {
	transform: translate3d(0,0,0); 
	-webkit-transform:translate3d(0,0,0);
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0.15rem;
	left: 0;
	right: 0;
	position: fixed;
	bottom: 0;
	z-index: 99;
	// align-items: center;
	// align-content: center;

	padding: 1rem;

	width: fit-content;
	min-width: 320px;
    // border: 1px solid red;;
	height: fit-content;
	background-color: var(--kargoroo-card-color);

	border-radius: 5px;

	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

	ion-card-subtitle {

		// font-size: 0.7rem;
		color: black;
	}

	ion-button{
		font-size: 2vh;
	}

	ion-badge {

		margin-bottom: 1.5rem;
	}

	p {

		// padding: 0;
		// border: 1px solid red;
		// margin: 0;
		margin-bottom: 0.3rem;
		// margin-right: 0.3rem;
		font-size: 2vh;
		display: flex;
		flex-direction: row;
		// align-items: center;
		// align-content: center;
		ion-icon{
			// border: 1px solid purple;
			font-size: 2.5vh;
			margin-right: 9px;
			// width: 2rem;
			min-width: 2.5vh;
		}
	}
	.earn-money{
		color: var(--kargoroo-text);
	}
	.delimiter{
		margin-top:0.5rem ;
		margin-bottom: 1.5rem;
		// mt-2 mb-4
		height: 0;
		width: 100%;
		background-color: transparent;
		opacity: .75;
		border-top: 2px dashed #9e9e9e;
	}
}

.overlayContainer:after {

	content:'';
    position: absolute;
    top: 100%;
    left: 70%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 10px white;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}


@media only screen and (min-width: 580px) {
    .overlayContainer {
	min-width: 450px;
	}

  }
