.Pickup {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0 36px 0 36px;
    // border: 5px solid orange;
    // min-height: 390px;
    // border: 2px solid red;;
    

    overflow-y: hidden;

    &::-webkit-scrollbar {
        display: block;
        width: 14px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #e7e7e7;
        border-radius: 20px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(126, 126, 126);
        border-radius: 20px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }

    .addressConfirmed{
        min-height: 400px !important;
    }


    .pickup-location-title {
        margin-top: 12px;

        p {
            color: var(--mrq-add-items-secondary-text);
            font-size: 16px;
            font-weight: bold;
        }
    }

    .confirmAddress {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 16px;

        ion-checkbox {
            margin: 5px;
            width: 28px;
            height: 28px;
        }

        p {
            display: flex;
            flex: 1 1 auto;
            margin-left: 8px;
            text-align: left;
            width: 0;
            font-size: 14px;
            font-weight: bold;
            color: var(--kargoroo-text);

            &.error {
                color: var(--mrq-red);
            }

        }
    }

    .editRQAddress {
        padding: 12px;
        color:var(--edit-rooquest-color);
        font-weight: bold;
    }

}