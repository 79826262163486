.confirmDeliveryModal {
  font-family: 'Montserrat-Regular';
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 8% 100px 8%;

  &::part(content) {
    --min-height: 100px;
    --height: auto;
    --border-color: #044800;
    --border-style: solid;
    --border-width: 4px;
    --border-radius: 25px;
    height: 486px;
  }
  .formErrorMsg{
    font-size: 10px;
    font-family: 'Montserrat-Regular';
    color: red;
    margin: 0px;
    padding: 0px;
    display: flex;
    margin-left: 20px;
    padding-top: 5px;
  }
  .order-name{
    margin: 0px !important;
  }
  .imageOfReceiptMessage {
    font-family: 'Montserrat-Regular';
    font-size: 14.5px;
  }

  .confirmCheckbox {
    position: absolute;
    bottom: 70px;
    ion-label {
      font-size: 14px;
      font-weight: bold;
      white-space: normal;
    }
  }

  &::part(backdrop) {
    --backdrop-opacity: 0.6;
  }

  .imageOfReceiptMessage {
    padding: 0 8px 0 8px;
  }
  .confirmationMessage {
    padding: 0 8px 0 8px;
  }

  .inputElements {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .rowOfImages {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
      align-content: center;

      padding: 8px 4px 8px 4px;
      margin-bottom: 14px;
      background: linear-gradient(rgb(238, 238, 238), rgb(224, 224, 224));

      .imageContainer {
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
        margin: 0 4px 0 4px;
        height: 80px;
        width: 80px;

        .receiptImage {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border: 2px solid #044800;
          border-radius: 15px;
        }

        .removeImage {
          position: absolute;
          top: 4px;
          right: 4px;
          width: 20px;
          height: 20px;
          border: 1px solid black;
          border-radius: 25px;
          background-color: white;

          display: flex;
          justify-content: center;
          align-items: center;

          ion-icon {
            font-size: 18px;
            color: black;
          }
        }
      }

      .addImageButton {
        padding: 0 8px 0 0;
        margin: 0 4px 0 4px;
        ion-button {
          margin: 0;
          height: 80px;
          width: 80px;
          --border-color: #044800;
          --border-radius: 15px;
          --border-style: solid;
          --border-width: 2px;
        }
        ion-icon {
          font-size: 32px;
        }
      }
    }
  }

  .actionButtons {
    position: absolute;
    bottom: 0px;
    width: 100%;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 2px 8px 4px 8px;

      ion-button {
        --border-color: #044800;
        --border-style: solid;
        --border-width: 2px;
        --border-radius: 15px;
        flex-grow: 1;
      }
    }
  }
}
