.singleitemreview ion-content {
    --overflow:auto !important;
    --background:var(--kargoroo-dark-background-color);
    .firstimage{
        background: var(--kargoroo-single-item-quest-modify-bg);
        // padding-right: 65px;
        border-radius: 15px;
        margin-bottom: 5px;
        padding-left: 0;
        // margin-top: 10px;
        height: 60px;
        position: relative;
        width: 100%;
        white-space: nowrap;
        .preview_images_main_wrapper {
            overflow: hidden;
            overflow-x: scroll;
            scrollbar-width: none; /* Firefox */
            .image_preview_wrapper {
                display: inline-block;
                .img_wrp {
                    width: 60px !important;
                    height: 60px !important;
                    object-fit: cover;
                    background-color: inherit;
                    margin-right: 10px;
                    border-radius: 15px;
                    background: white;
                    img {
                        width: 100% !important;
                        height: 100% !important;
                        object-fit: cover;
                        margin-right: 10px;
                        border-radius: 15px;
                        max-width: none;
                        left: 50%;
                        position: absolute;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
    .image_preview_wrapper .img_wrp {
        // width: 140px !important;
        // height: 140px !important;
        overflow: hidden;
        background-color: #f1f1f1;
    }
    .image_preview_wrapper .img_wrp img {
      width: 100%;
      height: 100%;
  }
      .img_wrp {
        display: inline-block;
        position: relative;
        margin-bottom: 5px;
        width: 100%;
        height: 50px;
      }
}
