@import './colors.scss';

.PostNewRooQuest {
    display: flex;
    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
        font-family: 'Poppins';
    }

    ion-content {
        position: relative;
        --background: var(--mrq-background);

    }

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        max-width: 800px;
        margin: 0 auto;
        
        .header {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            padding: 48px 16px 12px 16px;
        }

        .main {
            position: relative;
            background: var(--mrq-main-background);
            border-radius: 36px 36px 0 0;
            width: 100%;
            display: flex;
            flex: 1 1 auto;
            min-height: 0;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            .mainContent {
                display: flex;
                height: 100%;
                width: 100%;
                flex-direction: column;
                // justify-content: space-between;
            }

            .closeBtn {
                position: absolute;
                right: 20px;
                top: 16px;
                
                ion-icon {
                    font-size: 28px;
                    color: grey;
                }
            }

            .info {
                padding: 36px 36px 0px 36px;

                p {
                    font-size: 14px;
                    color: var(--mrq-info-text-p);
                    margin-bottom: 12px;
                    font-weight: bold;
                    font-family: 'Poppins';
                    margin-bottom: 4px;
                }

                h1 {
                    font-size: 32px;
                    font-weight: bold;
                    color: var(--mrq-info-text-h1);
                    margin-bottom: 12px;
                    font-family: 'Poppins';
                }
            }

            .swiperContainer {
                height: 100%;
                overflow: hidden;
                
                .swiper { 
                    height: 100%;
                }                
            }

            .swiperAndFooterContainer{
                height: 100%;
                overflow-y: scroll;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &::-webkit-scrollbar {
                    display: block;
                    width: 14px;
                }
            
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #e7e7e7;
                    border-radius: 20px;
                    border: 4px solid rgba(0, 0, 0, 0);
                    background-clip: padding-box;
                }
            
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: rgb(126, 126, 126);
                    border-radius: 20px;
                    border: 4px solid rgba(0, 0, 0, 0);
                    background-clip: padding-box;
                }
            }

            .hiddenScroll{
                overflow-y: hidden !important;
            }
            
            .minHeightSwiperContainer{
                // border: 1px solid red;
                min-height: 390px;
                
                .minHeightSwiper{
                    min-height: 390px;

                }

            }

            .addressConfirmedVisible{
                // border: 2px solid red;
                // border: 1px solid blue;
                min-height: 480px;
            }


            
            .footer {
                display: flex;
                flex-direction: row;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                padding: 16px 36px;

                // position: absolute;
                // bottom: 0;


                .controlBtn {
                    width: 90px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    color: white;
                    font-size: 14px;
                    font-weight: bold;
                    border-radius: 50px;
                    margin: 4px;   
                    padding: 16px;
                    margin-top: 20px;


                    &.continueBtn {
                        color: var(--continue-button-color);
                        background: var(--mrq-button-main-background);
                        width: 180px;
                        ion-icon {
                            position: relative;
                            left: 4px;
                        }

                        &:active {
                            background: var(--mrq-button-main-background-active)
                        }

                        &.disabled {
                            background: var(--mrq-button-secondary-background-active);
                            color: var(--mrq-grey);
                            opacity: 0.7;                            
                        }
                    }

                    &.backBtn {
                        color: var(--kargoroo-text);
                        width: 120px;

                        ion-icon {
                            position: relative;
                            right: 4px;
                        }   
                        
                        &:active {
                            background: var(--mrq-button-main-background-active);
                        }

                        &.disabled {
                            color: var(--mrq-grey);
                            opacity: 0.5;                            
                        }
                        
                    }
                }

                &.rmBack {
                    justify-content: center;
                    .backBtn {
                        transition: ease-in-out 0.3s;
                        display: none;
                    }
                }
            }
        }
    }

    .confetti {
        position: absolute;
        align-self: center;
        bottom: 0px;
        width: 10px;
        height: 5px;
    }    
}