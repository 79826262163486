.FullQuest .questcontainer .fullInnerContainer{
    height: calc(100vh - 60px) !important;
    .unitmethod {
        text-transform:capitalize;
    }
    ion-item {
        --ion-item-border-color: var(--kargoroo-ion-bottom-broder-color);  
      }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .FullQuest .questcontainer .fullInnerContainer .questreqdisplay{
        width: 550px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .FullQuest .questcontainer .fullInnerContainer .questreqdisplay{
        width: 550px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1601px) {
    .FullQuest .questcontainer .fullInnerContainer .questreqdisplay{
        width: 550px;
        margin: 0 auto;
    }
}