.NotificationMenu {
  font-family: 'Montserrat-Regular';

  ion-content ion-toolbar {
    --background: translucent;
  }
  ion-toolbar, ion-header {
    padding-top: 0px !important;
  }
  .title {
    text-align: left;
    margin-left: 20px;
  }

  h1 {
    font-size: 20px;
    word-wrap: none;
    font-family: 'Montserrat-Regular';
    font-weight: bold;
  }

  ion-content {
    //https://github.com/ionic-team/ionic-framework/issues/16596
    --overflow: hidden;
  }

  ion-list {
    height: 100vh;
  }

  .notificationMenuDiv {
    ion-label {
      font-family: 'Montserrat-Regular';
      font-size: 12px;
      font-weight: bold;
    }
  }

  .no_notifications {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    p {
      color: rgb(109, 109, 109);
      font-family: 'Montserrat-Regular';
      font-weight: bold;
      font-size: 18px;
    }
  }

  .notifications {
    ion-item.viewed {
      --background: var(--notif-viewed-color);
    }
    height: 100%;
    padding-bottom: 75px;
    overflow: auto;
  }

  .notifContent {
    display: flex;
    flex-direction: row;
    padding: 4px 0 4px 0;
    cursor: pointer;
    p {
      margin: 3px;
      padding: 0;
      font-family: 'Montserrat-Regular';
    }
    .notifIcon {
      width: 16px;
      height: 16px;
      color: var(--kargoroo-notification-icon);
      position: relative;
      top: 4px;
      padding-right: 3px;
    }

    .notifDetails {
      .notif_title {
        font-size: 15px;
        font-weight: bold;
      }

      .notif_rq {
        font-size: 14px;
        // font-weight: bold;
      }
      .notif_datetime {
        color: grey;
        font-size: 12px;
      }
    }
  }
}
