.ChatMessage {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  .chatMessageImg{
    width: 100px;
    height: 100px;
    margin: 5px 8px 5px 8px;
    cursor: pointer;
  }
  .chatMessageBubble {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 65%;
    margin: 2px 8px 2px 8px;
    min-height: 36px;
    justify-content: center;

    p {
      margin: 2px;
      padding: 8px;
      font-size: 16px;
      white-space: pre-wrap;
      color: black;
    }
  }

  .messageInfo {
    color: var(--kargoroo-my-req-del-text);
    font-size: 13px;
    padding: 0 8px 0 8px;
    margin: 0;
  }
}
