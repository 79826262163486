.ToastCards {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    z-index: 200000;
    width: 100%;
    align-items: center;
    justify-content: center;
}


.ToastCard {
    // z-index: 200001;
    --animate-duration: 1.3s;

    width: 100%;
    margin: 8px 8px 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;



    h1, h2, h3, h4, h5, h6, p {
      margin: 0;
      color: #f5f5f5;
    }  


    .cardContent {
      position: relative;
      padding: 16px 12px 12px 16px;
      border-radius: 12px;
      background-color: var(--toast-danger);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 95%;
      max-width: 600px;

      font-size: 13px;
      font-family: 'Poppins';
      
      .close {
          position: absolute;
          top: 0;
          right: 0;
          padding: 8px;
          cursor: pointer;

          ion-icon {
              font-size: 24px;
              color: black;

          }
      }

      .main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-left: 12px;

          .actionBtn {
            display: inline-block;
            background: var(--toast-danger-btn);
            border-radius: 6px;
            border: 1px solid black;
            border-color: var(--toast-danger-btn-border);
            padding: 8px 16px;
            color: white;
            font-size: 12px;

            align-items: center;
            justify-content: center;

            white-space: nowrap;
            margin-right: 8px;            
          }
        }


        .progressCircle {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;

          ion-icon {
            font-size: 18px;
            color: white;
          }

          .CircularProgressbar-path {
            stroke: #ffffff8e
          }
          .CircularProgressbar-trail {
            stroke: #ffffff27;
          }         
        }
      }

    &.success {
      background-color: var(--toast-success);
      .buttons {
        .actionBtn {
          background: var(--toast-success-btn);
          border-color: var(--toast-success-btn-border);
        }
      }
    }

    &.info {
      background-color: var(--toast-info);
      .buttons {
        .actionBtn {
          background: var(--toast-info-btn);
          border-color: var(--toast-info-btn-border);
        }
      }
    }

  }

    &.fadeout {
        animation: fadeOut ease 0.35s;
        @keyframes fadeOut {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
        }
    }  
}