.deliveryTracking{
  height: 100%;
}

.directions-button{
    border-radius: 50%;
    height: 60px;
    width: 60px;
    border : 1px solid rgb(123, 122, 122);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(243, 243, 243);

    img{
        height: 50px;
        width: 50px;
    }

    button{
        background-color: transparent;
    }
}

.deliveryHeader{
    height: 10%;
    

    .deliveryToolbar{
        height: 100%;
        display : flex;
        align-items: center;
        justify-content: space-between;
    }
}