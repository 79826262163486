.contentWrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: center;

  .item {
    flex: 0 0 auto;
  }

  .savedIcon {
    ion-chip {
      ion-label {
        color: rgb(224, 38, 38);
        margin-left: 2px;
        font-family: 'Montserrat-Regular';
      }
      border-color: rgb(241, 77, 77);
      ion-icon {
        color: rgb(224, 38, 38);
      }
      background: white;
    }
  }

  .filterIcon {
    ion-chip {
      ion-label {
        color: rgb(88, 88, 88);
        margin-left: 2px;
        font-family: 'Montserrat-Regular';
      }
      border-color: rgb(88, 88, 88);
      ion-icon {
        color: rgb(88, 88, 88);
      }
      background: white;
    }
  }

  .refreshIcon {
    ion-chip {
      ion-label {
        color: rgb(41, 171, 223);
        margin-left: 2px;
        font-family: 'Montserrat-Regular';
      }
      border-color: rgb(41, 171, 223);
      ion-icon {
        color: rgb(41, 171, 223);
      }
      background: white;
    }
  }
}

.radius-center{
  width: 80% !important;
  margin: 0 auto;
}

.activeFilters {
  .distanceChip {
    ion-chip {
      font-size: 12px;
      font-family: 'Montserrat-Regular';
      font-weight: bold;
      height: 24px;
    }
  }
}

.filterModal {
  font-family: 'Montserrat-Regular';
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 8% 100px 8%;
  color: var(--kargoroo-modal-text);

  &::part(content) {
    --border-color: #044800;
    --border-style: solid;
    --border-width: 4px;
    --border-radius: 25px;
    height: 350px;

    ion-page {
      color: red;
    }
  }

  &::part(backdrop) {
    --backdrop-opacity: 0.6;
  }

  .inputElements {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    input {
      text-decoration: none;
      text-align: center;
      font-weight: bold;
      outline: none;
      border: none;
      color: var(--mrq-green);
      font-size: 24px;
      width: 20px;
      border-bottom: 2px green solid;
      border:  2px green solid;
      border-color: var(--mrq-green);
      margin-left: auto;
      margin-right: auto;


    }
  }

  .actionButtons {
    position: absolute;
    bottom: 0px;
    width: 100%;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 2px 8px 4px 8px;

      ion-button {
        --border-color: #044800;
        --border-style: solid;
        --border-width: 2px;
        --border-radius: 15px;
        flex-grow: 1;
      }
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

