.DeliveryModalContent
{
    .avatar {

        border-radius: 10px;
    }
    
    .profileStats {
    
        background-color: rgb(245, 245, 245);
        border-radius: 10px !important;
        padding: 0.2rem;
        align-content: center;
    }
    
    .profileStat {
    
        text-align: center;
        margin: 0 auto;
    
        ion-card-title {
    
            color: rgb(68, 68, 68);
            font-size: 0.8rem;
        }
    
        ion-card-subtitle {
    
            font-size: 0.6rem;
        }
    }
    
    .profileInfo {
    
        text-align: left;
        margin-top: -0.2rem;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    
    .profileName {
    
        color: rgb(59, 59, 59) !important;
        font-weight: 500 !important;
        p{
            color: var(--kargoroo-text) !important;
            
        }
    }
 
    .profileCard {
    
        ion-icon {
    
            font-size: 1.75rem;
            color: #5893fa;
            margin-bottom: 1rem;
        }
    }
    
    .profileStatusContainer {
    
        margin-top: -2rem;
        margin-bottom: -2rem;
    
        ion-card-content {
    
            margin-top: -1rem !important;
        }
    }
    
    
    .profileStatus {
    
        ion-card-subtitle {
    
            margin-top: 0.35rem;
            margin-left: 1rem;
        }
    }
    
    .profileActionCard {
    
        padding-top: 0.5rem;
    
        ion-icon {
    
            font-size: 1.75rem;
            color: #5893fa;
            margin-top: -0.3rem;
        }
    }
    
    .buttons{
        padding: 0.2rem;
        margin-top: 0.95rem;
        align-content: center;
        height: 60px;
    }
    
    .icon{
        border-radius: 50%;
        // width: 50px;
        // background-color: rgb(245, 245, 245);
    }
    
    
    .dropoffIcon{
        ion-icon{
            font-size: 64px;
        }
    }
    
    .profileHeader {
        margin-top: 15px;
    }

    .del-modal-btn{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        height: 48px;
        width: 48px;
        border-radius: 50px;
        background:#F0F0F0;
       }    
        ion-icon {
            font-size: 22px;
            color: black;
        }
    .del-modal-btn:hover{
        background:#D8D8D8;
        cursor:pointer;
    }
    .del-modal-btn:active {
        background: #F0F0F0;
        -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
        -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
        box-shadow: inset 0px 0px 5px #c1c1c1;
        outline: none;
    }
    @media screen and (max-width: 400px) { 
    .del-modal-btn-text{
        font-size: 10px;
    }
  }
}