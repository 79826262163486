.startingDelivery{
    ion-card{
        position: fixed;
        bottom : 25px;
        width: 95%;
        margin-right: auto;
        margin-left: auto;
        // height: 30vh;
        // left:50%
    }
}

