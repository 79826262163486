.loader-container {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 11;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    span{
        z-index: 11;
    }
}