
.AddItems {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0 36px 0 36px;

    .itemsTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 2px;
        font-family: 'Poppins-SemiBold';

        .title {
            color: var(--mrq-add-items-secondary-text);
            font-size: 16px;
            font-weight: bold;
            ion-icon{
                color: var(--mrq-green-dark) !important;
            }
        }

        section {
            color: var(--mrq-add-items-accent);
            font-size: 16px;
            font-weight: bold;
        }
    }    

    .items {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--ion-item-background, var(--ion-background-color, #fff));      
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        padding: 8px 12px; 
        margin-bottom: 3px;

        overflow-y: auto;

        &::-webkit-scrollbar {
            display: block;
            width: 12px;
        }
    
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 20px;
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 20px;
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }        

        .addNewItem {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 8px 0 0 0;

            ion-icon {
                font-size: 32px;
                color: var(--mrq-add-items-accent);
                padding: 4px;
                width: 100px;
            }
        }        

        .item {
            display: flex;
            flex-direction: column;
            padding: 6px 2px;
            border-bottom: 1px solid #e6e6e6;
            width: 100%;

            .item-title {
                font-weight: bold;
                color:var(--mrq-add-items-secondary-text-light);
            }

            .cost {
                display: flex;
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                font-size: 14px;
                font-family: 'Poppins-SemiBold';
                // color: #808080;
                color: var(--mrq-add-items-secondary-text-light);

                .total {
                    font-weight: bold;
                }
            }

            .noItems {
                text-align: center;
                 color: var(--add-items-click-color);
            }
        }
    } 
}