.savings {
    // font-family: 'Poppins';

    .itemCost {
        color: var(--mrq-add-items-secondary-text);
        font-size: 14px;
    }

    ion-item {
        --border-color: #6b6b6b;
    }
    ion-icon {
        visibility: hidden;

    }
}