.leftMenu {
  font-family: 'Montserrat-Regular';

  ion-content ion-toolbar {
    --background: translucent;
  }
  ion-toolbar, ion-header {
    padding-top: 0px !important;
  }
  .title {
    text-align: left;
    margin-left: 20px;
  }

  h1 {
    font-size: 20px;
    word-wrap: none;
    font-family: 'Montserrat-Regular';
    font-weight: bold;
  }

  ion-content {
    //https://github.com/ionic-team/ionic-framework/issues/16596
    --overflow: hidden;
  }

  ion-list {
    height: 100vh;
  }

  .leftMenuDiv {
    ion-label {
      font-family: 'Montserrat-Regular';
      font-size: 12px;
      font-weight: bold;
    }  
  }
 
  // ion-menu {
  //   --background: white;
  // }

  .btnLabel {
    margin-left: 6px;
  }
  ion-item {
    --ion-item-border-color: var(--kargoroo-ion-bottom-broder-color);  
  }
}

// .item{
//   .item-native{
//     .item-inner {
//       border-bottom: 1px solid white !important;
//     }
//   }
// }
