.green-modal {
  .modalContent {
    margin: 0;
    padding: 0;
    height: 100vh;
    background-color: var(--kargoroo-address-card-bg);

    .modalBody {
      position: fixed;
      width: 100%;
      top: 78px;
      height: calc(100% - 78px);
      z-index: 1;
      overflow-y: auto;
      scrollbar-width: none; /* Firefox */
    }
    .modalHeader .titleContainer #headerTitle {
      margin-left: 20px;
   }
  }
}
