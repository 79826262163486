.RooQuestInformation {
    display: flex;
    flex-direction: column;

    &::part(content) {
        --height: 100%;
        --width: 100%;
    }

    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
        padding: 0;
        font-family: 'Poppins';
        color: var(--title-color,black)
    }

    ion-content {
        position: relative;
        --background: var(--mrq-main-background);

    }

    .modalContent {
        // position: relative;
        display: flex;
        flex-direction: column;
        padding: 20px 12px 0 12px;
        width: 100%;
        height: 100%;

        margin: auto;

        max-width: 600px;
        

        .header {
            position: relative;
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding: 24px 16px 12px 16px;

            .backBtn {
                position: absolute;
                left: 4px;

                ion-icon {
                    font-size: 36px;
                    color: var(--mrq-grey);
                }
            }

            h1 {
                font-size: 22px;
                font-weight: bold;
                position: relative;
                left: 8px;
                top: -2px;
            } 
            @media screen and (max-width: 400px) { 
                h1{
                    left: 20px;
                }
              }           
        }

        .actionBtns {
            display: flex;
            flex-direction: row;
            height: 50px;
            margin-bottom: 8px;

            .deliverBtn {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                background: var(--mrq-button-main-background);
                border-radius: 24px;
                padding: 12px;
                flex-grow: 1;
                p {
                    font-size: 16px;
                    color: var(--title-color,white);
                    font-weight: bold;
                }

                &:hover {
                    cursor: pointer;
                }

                &:active {
                    background: var(--mrq-button-main-background-active);
                }
            }

            .contactBtn {
                margin-left: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: var(--mrq-button-secondary-background);
                border-radius: 50px;
                padding: 12px;

                width: 50px;
                height: 50px;

                ion-icon {
                    font-size: 22px;
                    color: black;
                }

                &:hover {
                    cursor: pointer;
                }

                &:active {
                    background: var(--mrq-button-secondary-background-active);
                }
            }
        }

        .visibleToPublic {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
                padding-right: 12px;
            }

            ion-toggle {
                height: 20px;
                width: 36px;

                --handle-width: 16px;
                // --background: #000;
                 --background-checked: #00bd00;

            }
        }


        .mainContent {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: 0 18px 28px 18px;

            overflow-y: auto;
            &::-webkit-scrollbar {
                display: block;
                width: 14px;
            }
        
            /* Track */
            &::-webkit-scrollbar-track {
                background: #e7e7e7;
                border-radius: 20px;
                border: 4px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
            }
        
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgb(126, 126, 126);
                border-radius: 20px;
                border: 4px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
            }

            .sectionTitleRow {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                section {
                    font-weight: bold;
                    color: var(--mrq-add-items-accent);
                }   
            }

            .sectionTitle {
                color: var(--mrq-add-items-secondary-text);
                font-size: 16px;
                font-weight: bold;      
                margin-bottom: 2px;    
            }



            .estimatedCostContainer {
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                white-space: pre;

                width: 100%;

                padding: 12px 0 0 0;

                p {
                    margin: 0;
                    margin-left: 12px;
                    font-size: 14px;
                    color: var(--mrq-add-items-secondary-text);
                    font-weight: bold;
                }

                section {
                    color: var(--mrq-add-items-accent);
                    font-weight: bold;
                }  
            }

            .deliveryInfo {
                margin-top: 18px;                

                .addressContainer {
                    padding: 12px;
                }

                .seeOnMapContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-top: 1px solid #c9c9c9;
                    margin: 0 12px;
                    padding: 8px;

                    p {
                        font-size: 14px;
                        color: var(--mrq-add-items-secondary-text);
                    }

                    ion-icon {
                        font-size: 18px;
                        color: var(--mrq-add-items-accent);
                    }
                }
            }

            .rqinfo-section {
                padding-top: 18px;

                .additionalNoteContainer {
                    padding: 12px 14px;

                    p {
                        font-size: 14px;
                        color: var(--mrq-add-items-secondary-text);
                        white-space: pre-wrap;
                    }
                }

                .statusCard {
                    padding: 8px 12px;
                    p {
                        font-size: 14px;
                        color: var(--mrq-add-items-secondary-text);
                        font-weight: bold;
                        margin-bottom: 2px;
                    }
                }
            }

            .deliveryOffers {
                margin-top: 18px;
                

                .offersContainer {
                    display: flex;
                    flex-direction: column;                    
                }
            }

        }

        .co2Saved {
            margin-bottom: 4px;
            p {
                margin: 0;
                // margin-top: 12px;
                font-size: 13px;
                text-align: center;
                color: var(--mrq-add-items-secondary-text) !important;
                font-weight: bold;

                &:first-child {
                    margin-top: 0px;
                    color: black;
                }
            }
        }

    }
    .MyRQInfo-extraPadding {
        padding-bottom: 72px;
    }

}


.footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 8px 36px;
    // position: absolute;
    // bottom: 0;


    .controlBtn {
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        color: white;
        font-size: 14px;
        font-weight: bold;
        border-radius: 50px;
        margin: 4px;   
        padding: 16px;


        &.continueBtn {
            color: var(--continue-button-color);
            background: var(--mrq-button-main-background);
            width: 180px;
            ion-icon {
                position: relative;
                left: 4px;
            }

            &:active {
                background: var(--mrq-button-main-background-active)
            }

            &.disabled {
                background: var(--mrq-button-secondary-background-active);
                color: var(--mrq-grey);
                opacity: 0.7;                            
            }
        }

        &.backBtn {
            color: var(--kargoroo-text);
            width: 120px;

            ion-icon {
                position: relative;
                right: 4px;
            }   
            
            &:active {
                background: var(--mrq-button-secondary-background-active);
            }

            &.disabled {
                color: var(--mrq-grey);
                opacity: 0.5;                            
            }
            
        }
    }

    &.rmBack {
        justify-content: center;
        .backBtn {
            transition: ease-in-out 0.3s;
            display: none;
        }
    }
}