// .register{
  ion-content {
    --background: transparent;
    --overflow : scroll;
  }
  
  .homepage {
    background-color: rgba(23, 23, 23, 1);
  }
  
  .registerPage {
    background-color: rgba(23, 23, 23, 1);
    ion-content {
      --overflow: scroll;
    }
  }
  
  .home-container {
    background-color: rgba(23, 23, 23, 1);
    height: 100%;
    width: 100%;
    display: block;
    text-align: center;
    font-family: 'Montserrat-Regular';
  
    .homepage-content {
      background-color: rgba(23, 23, 23, 1);
    }
  
    .top-image {
      width: 100%;
      height: 20vh;
      position: relative;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: white !important;
    }
    .form-inputs {
      position: relative;
  
      .form-input {
        color: white;
        font-size: 20px;
      }
    }
    .form-email {
      margin-top: 20px;
    }
  
    .form-password {
      margin-top: 10px;
    }
  
    .signin-btn {
      margin-top: 50px;
      margin-left: 10%;
      margin-right: 10%;
      height: 50px;
      font-size: 18px;
      text-transform: none;
    }
  
    .haveaccount-btn {
      color: #00bd00;
      font-size: 16px;
      text-decoration: none;
      position: relative;
      top: 12px;
    }
  
    .haveaccount-btn:hover {
      color: #7eeb7e;
    }
  
    .alternateLoginContainer {
      position: relative;
      color: white;
      top: 100px;
      font-size: 18px;
  
      .row {
        flex-direction: row;
        position: relative;
        top: 10px;
        background-color: rgba(23, 23, 23, 1);
      }
  
      .social_login {
        width: 42%;
        margin-left: 4px;
        margin-right: 4px;
        font-size: 14px;
        font-family: 'Montserrat-Regular';
  
        .googlelogo {
          width: 20px;
          position: relative;
          top: 1px;
        }
  
        .facebooklogo {
          height: 22px;
          position: relative;
          top: 1px;
        }
  
        .google {
          position: relative;
          color: white;
  
          .google-text {
            position: relative;
            bottom: 3px;
            margin-left: 6px;
          }
        }
  
        .facebook {
          color: white;
  
          .facebook-text {
            position: relative;
            bottom: 3px;
            margin-left: 6px;
          }
        }
      }
    }
  }

// }

