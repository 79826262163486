.modalHeader {
  background-color: var(--kargoroo-dark-background-color);
  position: fixed;
  top: 0px;
  border-radius: 0 0 25px 25px;
  height: 78px;
  width: 100%;
  z-index: 2;
  text-align: center;

  .titleContainer {
    h3 {
      margin: 0;
      padding: 0;
      font-size: 21px;
    }

    @media screen and (max-width: 350px) {
      h3 {
        font-size: 18px;
      }
    }

    @media screen and (max-width: 300px) {
      h3 {
        font-size: 16px;
      }
    }

    // position: fixed;
    position: relative;
    top: 27px;
    // margin-left: 40px;
    right: 0px;
    // width: calc(100% - 24px);

    #headerTitle {
      font-family: 'Montserrat-Regular';
      font-weight: bold;
      color: white;
    }
  }
}
