.ChatModal {
  .modalContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    // background-image: linear-gradient(rgb(76, 190, 72), rgb(23, 139, 0));
    background-color: rgb(235, 235, 235);
    // .uploadedImage {
    //   margin: 8px;
    //   height: 60px;
    //   width: 60px;
    //   object-fit: cover;
    //   align-self: center;
    //   border: 2px solid #084400;
    //   border-radius: 25px
    // }
    .imageUploadBtn {
      align-self: center;
      font-size: 14px;
      font-family: 'Montserrat-Regular';
      --background: #268819;
      --background-activated: #29971b;
      --background-hover: #29971b;
      height: 28px;
      width: 170px;
      margin: 16px 0px 16px 0px;
    }
    .imagewrapper{
      margin: 8px;
    }
    .bodyContent {
      display: flex;
      flex-direction: column;
      text-align: center;
      background-color: var(--kargoroo-my-req-del-bg);
      flex-grow: 1;
      margin: 8px 8px 4px 8px;
      border: 4px #084400 solid;
      border-radius: 25px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2),
        0 4px 12px 0 rgba(0, 0, 0, 0.2);
      overflow: hidden;
      .itemInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .rqImg {
          height: 54px;
          width: 53px;
          margin: 4px;
          margin-left: 8px;
          border: 1px solid #084400;
          border-radius: 50px;
        }

        .textComponent {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
          text-align: end;
          font-family: 'Montserrat-Regular';
          
          h5 {
            padding: 0;
            margin: 0;
            font-size: 17px;
            font-weight: bold;
            color: var(--kargoroo-my-req-del-text);
          }

          p {
            padding: 0;
            margin: 0;
            font-size: 14px;
            font-weight: bold;
            padding-left: 60px;
            color: var(--kargoroo-my-req-del-text);
          }
        }
      }

      .outerMessagesWrapper {
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        .messages {
          padding: 8px;
        }
      }
    }

  .chatactionContent {
      background-color: lightgrey;
      margin: 4px 8px 8px 8px;
      border: 4px #00bd00 solid;
      border-radius: 25px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2),
        0 4px 12px 0 rgba(0, 0, 0, 0.2);
      font-family: 'Montserrat-Regular';
    .firstimage{
      background: var(--kargoroo-single-item-quest-modify-bg);
      padding-right: 10px;
      padding-left: 10px;
      border-radius: 15px;
      margin-bottom: 5px;
      padding-left: 0;
      margin-top: 5px;
      height: 60px;
      position: relative;
      width: 100%;
      white-space: nowrap;
      .preview_images_main_wrapper {
          overflow: hidden;
          overflow-x: scroll;
          .image_preview_wrapper {
              display: inline-block;
              .img_wrp {
                  width: 60px !important;
                  height: 60px !important;
                  background-color: inherit;
                  margin-right: 10px;
                  border-radius: 15px;
                  background: white;
                  cursor: pointer;
                  img {
                      width: 100% !important;
                      height: 100% !important;
                      object-fit: cover;
                      margin-right: 10px;
                      border-radius: 15px;
                      max-width: none;
                      left: 50%;
                      position: absolute;
                      transform: translateX(-50%);
                  }
              }
          }
      }
  }
}
  .image_preview_wrapper .img_wrp {
    // width: 140px !important;
    // height: 140px !important;
    overflow: hidden;
    background-color: #f1f1f1;
}
  .image_preview_wrapper .img_wrp img {
    width: 100%;
    height: 100%;
}
    .img_wrp {
      display: inline-block;
      position: relative;
      margin-bottom: 5px;
      width: 100%;
      height: 50px;
      
    }
    .close {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 16px;
      color: red;
      font-weight: bold;
      border-radius: 15px;
      border: 1px solid black;
      background-color: white;
    }
    .chatimagebtn{
      margin-right: 10px;
      cursor: pointer;
    }
    .actionContent {
      background-color: var(--kargoroo-my-req-del-bg);
      margin: 4px 8px 8px 8px;
      border: 4px #00bd00 solid;
      border-radius: 25px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2),
        0 4px 12px 0 rgba(0, 0, 0, 0.2);
      font-family: 'Montserrat-Regular';
      
      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        margin: 0px;
        padding: 4px 8px 4px 8px;

        // ion-textarea {
        //   // overflow: auto;
        //   position: relative;
        //   font-size: 20px;
        // }
        textarea {
          font-size: 17px;
          padding: 6px 0 4px 6px;
          border-style: none;
          border-color: Transparent;
          resize: none;
          outline: none;
          height: 92px;
          flex-grow: 1;
          display: inline-block;
          vertical-align: middle;
          background-color:var(--kargoroo-message-chat-bg);
          color: var(--kargoroo-my-req-del-text);
        }

        ion-icon {
          font-size: 24px;
          color: var(--kargoroo-icon-text-color);
        }

        ion-fab-button {
          height: 48px;
          width: 48px;
          margin: 4px;
          position: fixed;
          right: 0;
          margin-right: 25px;
          --border-color: #084400;
          //   --border-radius: 15px;
          --border-style: solid;
          --border-width: 2px;
        }
      }
    }
  }
}
