body {
    font-family: sans-serif;
  }
  
  input {
    border: 1px solid #999;
    padding: 0.5rem;
    width: 300px;
  }
  
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  
  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    color:var(--suggestions-color);
  
  }
  
  .suggestions li {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .itemPrice{
      margin-left: auto;
    }

    .itemTitle{
      width: 50%;
      margin-left: 20px;
    }

    img {
      height: 5.5%;
      width: 5.5%;
    }
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color:var(--suggestion-active-color);
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }

  


  