
  // .header{
  .searchBar{
    // border: 1px solid;
    height: 12vh;
    background-color: var(--kargoroo-card-color);
  }
  
  .row{
    display: flex;
    gap: 10px;
    .options{
      display: flex;
      font-size: 16px;
      text-align: center;
      align-items: center;
      color: var(--kargoroo-my-req-del-text-header);
      --border-radius: 60px;
      margin-top: 14px;
      height: 42px;
      text-transform: none;
    }
  }
  .optionicon {
      width: 30px;
      height: 40px;
      border-radius: 50px;
      text-align: center;
      vertical-align: middle;
      color: white;
  }
  .searchInput{
      font-size: 14px;
      border: 1px var(--input-container-border-color) solid;
      border-radius: 80px;
      width: 90%;
      height: 52px;
      background-color: var(--input-container-dark-background);
      margin-top: 12px;
      margin-left: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      span {
        align-items: center;
        padding: 10px;
        font-size: 24px;
        color: var(--clear-button-color);
        border: 1px;
        width: 45px;
        ion-icon{
          color: grey;
        }
      }
  }
  .header {
  input {
    color: var(--input-color);
    overflow-x: scroll;
    padding: 8px 12px;
    height: 40px;
    background-color: var(--input-background-color);
    // background-color: red;
    text-decoration: none;
    border: none;
    flex: 1 1 auto;
    border-radius: 80px;          
  
    &:focus {
        outline: none;
    }
  }
}
  .storeName{
    border: 1px solid #cccccc;
  }
  
  // ion-card {
  //     height: 20%;
  // }
  .RQFeedCardContent {
      display: flex;
      flex-direction: column;
      
  
      .mainContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
  
        .rightRQInfo {
          display: flex;
          flex-direction: column;
          min-height: 100%;
          min-width: 80px;
          align-items: flex-end;
          justify-content: space-between;
          padding-top: 0px;
  
          font-family: 'Montserrat-Regular';
          
          .saveComponent {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 5px;
  
            p {
              margin: 0;
              padding: 0;
            }
          }
  
          .posted {
            padding-top: 12px;
            padding-bottom: 4px;
            padding-right: 4px;
            text-align: right;
            font-family: 'Montserrat-Regular';
            display: flex;
            p {
              span {
                font-weight: bold;
              }
              color: var(--kargoroo-my-req-del-text);
              font-size: 11px;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    
      ion-card {
        @media (max-height: 685px) {
          height: 90vh;
        }
    
        @media (max-height: 600px) {
          height: 80vh;
          bottom: -62vh;
        }
    
        @media (max-height: 525px) {
          height: 70vh;
          bottom: -72vh;
        }
    
        @media (max-height: 415px) {
          height: 65vh;
          bottom: -76vh;
        }
    
        .setupDeliveryAccount {
          border: 1px solid rgb(236, 76, 76);
          border-radius: 25px;
          padding: 0 8px 0 8px;
          margin: 0 8px 0 8px;
          height: 28px;
          text-align: center;
          background-color: rgb(255, 236, 236);
    
          ion-icon {
            position: relative;
            top: 3px;
            font-size: 20px;
          }
          p {
            margin: 0;
            padding: 0px 0 4px 0;
            color: rgb(236, 76, 76);
            font-size: 16px;
            font-family: 'Montserrat-Regular';
            font-weight: bold;
          }
        }
        .setupDeliveryAccount:active {
          background-color: rgb(241, 151, 151);
        }
        }
      }
    
      .backMenuButton {
        text-align: center;
        margin: 18px 8px 0px 12px;
        padding-top: 6px;
        position: fixed;
        top: 50px;
        background-color: var(--kargoroo-background-color,#00bd00);
        width: 44px;
        height: 44px;
        border-radius: 25px;
        font-size: 32px;
        cursor: pointer;
        ion-icon {
          color: white;
          --ionicon-stroke-width: 38px;
        }
      }
    
      .rqItems {
        // background-color: purple;
        overflow-y: scroll;
        height: 90%;
        padding-bottom: 35vh;
        text-align: center;
        scrollbar-width: none; /* Firefox */
        ion-card {
          border: 1px solid #048800;
          border-radius: 20px;
          cursor: pointer !important;
        }
    
        .noRQs {
          margin-top: 20px;
          height: 140px;
        }
      }
      .routeInfo {
        display: flex;
        flex-direction: row;
      }
      .offersubmitted {
        font-weight: bold;
        font-size: 14px;
        .offersubmittext {
          margin:2px;
          color: var(--kargoroo-background-color, #00bd00);
        }
      }
      .showInlinebtn{
        display:flex;

        ion-range{
            background-color: #048800;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
       }
       .addclosebtn{
        flex: 1;
      
       }
     .closeButton{
      width: 33px;
      height: 32px;
      border-radius: 17px;
      font-size: 32px;
      cursor: pointer;
        ion-icon { 
          color: #737373;
        }
     }
     
    .selectlabel{
      margin-left: 18px;
      color: var(--mrq-add-items-secondary-text);
      padding-bottom: 4px
    }
    ion-popover#popover-bottom::part(content) {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      width: 100%;
      height: 100%;
      background-color: var(--kargoroo-card-color);
    }
    .deliverbtns{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 380px;
      margin-left: auto;
      margin-right: auto;
    }
    // .buttons {
    //   width: 190px;
    //   border: 1px solid red;
    // }
    ion-select.deliverselect  {
      --placeholder-color: black;
      --placeholder-opacity: 1;
      // padding-left: 5px;
      width: 100%;
      justify-content: center;
      cursor: pointer;
      font-size: 15px !important;
      padding: 5px 15px 5px 5px !important;
      border: 1px solid #cccccc !important;
      border-radius: 0 !important;
      box-sizing: border-box;
      min-height: 40px !important;
    }
   .resetbtn{
    float: right;
    --background: white;
    color: black;
    --border-radius: 60px;
    --border-width: 1px;
    height: 50px;
    width: 140px;
    text-transform: none;
    font-size: 20px;
    margin: 0px 4px 8px 4px;
   }
   .reseticon {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    text-align: center;
    vertical-align: middle;
    background-color: #00bd00;
  }
  .reseticonspan{
      width: 35px;
      margin-left: 5px;
      height: 35px;
      border-radius: 50px;
      text-align: center;
      vertical-align: middle;
      background-color: #00bd00;
      color: white;
  }
   .searchbtn{
    float: left;
    --background: #00bd00;
    --border-radius: 60px;
    --border-width: 1px;
    height: 50px;
    width: 140px;
    text-transform: none;
    font-size: 20px;
    margin: 0px 4px 8px 4px;
   }
  .delivermodalbtn{
    margin-top: 20px;
  }
  .radiusLabel{
    color: var(--kargoroo-payment-page-text) !important;
  }
  @media only screen and (max-width: 767px) {
    .row {
      .options{
        display: none;
      }
    }
    .searchInput {
      width: 80%;
    }
    .filterspan{
    width: 40px;
    height: 40px;
    border-radius: 50px;
    text-align: center;
    vertical-align: middle;
    background-color: #00bd00;
    color: white;
    margin-top: 15px;
    margin-left: 5px;
    }
  }
  
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .delivererFeedContent {
        .bottom-drawer{
          width: 550px;
          margin: 0 auto;
        }
      }
      ion-popover#popover-bottom::part(content) {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 90%;
        height: 350px;
        padding: 20px;
        background-color: var(--kargoroo-card-color);
      }
      .openhousecheckbox{
        margin-top: 15px;
      }
      .optionicon.filtermob{
        display: none;
      }
      .closeButton{
        margin-top: -15px;
        margin-right:-10px;
      }
      .filterweb{
        width: 40px;
        height: 40px;
        border-radius: 50px;
        text-align: center;
        vertical-align: middle;
        background-color: #00bd00;
        color: white;
        margin-left: 3px;
        margin-top: 2px;
        margin-right: -18px;
        }
    }
  
    
  
    @media screen and (min-width: 1200px) and (max-width: 1600px) {
      .delivererFeedContent {
        .bottom-drawer{
          width: 550px;
          margin: 0 auto;
        }
      }
      ion-popover#popover-bottom::part(content) {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 90%;
        height: 350px;
        padding: 20px;
        background-color: var(--kargoroo-card-color);
      }
      .openhousecheckbox{
        margin-top: 15px;
      }
      .optionicon.filtermob{
        display: none;
      }
      .closeButton{
        margin-top: -15px;
        margin-right:-10px;
      }
      .filterweb{
      width: 40px;
      height: 40px;
      border-radius: 50px;
      text-align: center;
      vertical-align: middle;
      background-color: #00bd00;
      color: white;
      margin-left: 3px;
      margin-top: 2px;
      margin-right: -18px;
      }
    }
  
    @media screen and (min-width: 1601px) {
      .delivererFeedContent {
        .bottom-drawer{
          width: 550px;
          margin: 0 auto;
        }
      }
      ion-popover#popover-bottom::part(content) {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 90%;
        height: 350px;
        padding: 20px;
        background-color: var(--kargoroo-card-color);
      }
      .openhousecheckbox{
        margin-top: 15px;
      }
      .optionicon.filtermob{
        display: none;
      }
      .closeButton{
        margin-top: -15px;
        margin-right:-10px;
      }
      .filterweb{
        width: 40px;
        height: 40px;
        border-radius: 50px;
        text-align: center;
        vertical-align: middle;
        background-color: #00bd00;
        color: white;
        margin-left: 3px;
        margin-top: 2px;
        margin-right: -18px;
        }
    }
  // }
