.singleitempage ion-content {
    --overflow:auto !important;
    --background:var(--kargoroo-dark-background-color);
    .singleItemBtn {
        height: 60px;
        width: 60px;
        background: var(--kargoroo-single-quest-ion-item-bg);
        border-radius: 15px;
        align-items: center;
        justify-content: center;
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
    .firstimage{
        background: var(--kargoroo-single-item-quest-modify-bg);
        padding-right: 65px;
        border-radius: 15px;
        margin-bottom: 5px;
        padding-left: 0;
        margin-top: 10px;
        height: 60px;
        position: relative;
        width: 100%;
        white-space: nowrap;
        .preview_images_main_wrapper {
            overflow: hidden;
            overflow-x: scroll;
            scrollbar-width: none; /* Firefox */
            .image_preview_wrapper {
                display: inline-block;
                .img_wrp {
                    width: 60px !important;
                    height: 60px !important;
                    background-color: inherit;
                    margin-right: 10px;
                    border-radius: 15px;
                    background: white;
                    cursor: pointer;
                    img {
                        width: 100% !important;
                        height: 100% !important;
                        object-fit: cover;
                        margin-right: 10px;
                        border-radius: 15px;
                        max-width: none;
                        left: 50%;
                        position: absolute;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
    .plusbtn{
        font-size: 28px;
        margin: 0 auto;
        color: white;
    }
    .estimatedtext{
        font-size: 13px;
        font-weight: bold;
        color: grey;
        padding: 5px 25px;
        margin-bottom: 0px;
        margin-top: 0px;
    }
    // .uploadedImage{
    //     height: 60px;
    // }
    .inputField {
        --border-color: var(--kargoroo-ion-bottom-border);
        padding-right: 12px;
        ion-label {
          font-family: 'Montserrat-Regular';
          font-size: 14px;
          font-weight: bold;
        //   color: rgb(240, 70, 70);
        }
        ion-input{
            font-size: 13px;
        }
        ion-textarea{
            font-size: 13px;
        }
      }
      .disabledopts{
          color: grey;
      }
      .estimatedcosttext{
        color: grey;
        text-align: center;
        justify-content: center;
        font-weight: bold;
        font-size: 13px;
        padding: 12px;
        margin: 0px;
      }
      .estimatedSeeMore {
          color: var(--kargoroo-card-text-color);
      }
      .image_preview_wrapper .img_wrp {
        // width: 140px !important;
        // height: 140px !important;
        overflow: hidden;
        background-color: #f1f1f1;
    }
    .image_preview_wrapper .img_wrp img {
      width: 100%;
      height: 100%;
  }
      .img_wrp {
        display: inline-block;
        position: relative;
        margin-bottom: 5px;
        width: 100%;
        height: 50px;
      }
      .close {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 16px;
        color: red;
        font-weight: bold;
        border-radius: 15px;
        border: 1px solid black;
        background-color: white;
      }
      .modify-btn{
        background:var(--kargoroo-single-quest-ion-item-bg);
        border-radius: 15px;
        color: white;
        padding: 8px;
        border-radius: 10px;
        cursor: pointer;
      }
     
    .modify-container{
      background-color: lightgrey;
      position: relative;
      margin-right: 22px;
      border-radius: 10px;
      height: 35px !important;
      // margin-left: 22px;
    }
       .modify-container .flex-child-left {
        padding-right: 82px;
        height: 35px !important;
    }
    // .modify-container .flex-child-left ion-item.GAAC_input{
    //   height: 36px !important;
    //   padding: 0;
    //   margin-right: -40px;
    //   margin-top: -6px;
    // }
    .modify-container .flex-child-left ion-item,.modify-container .flex-child-left textarea{
      height: 35px !important;
      padding: 0;
      margin-right: -40px;
      margin-top: -6px;
    }
    .modify-container .flex-child-left ion-input , .modify-container .flex-child-left .GoogleAddressAutoCompleteContainer{
      height: 35px !important;
      margin-top: -6px;
  }
 
    .modify-container .flex-child-left input ,.modify-container .flex-child-left textarea{
      background-color: var(--kargoroo-single-item-quest-modify-bg);
      height: 35px !important;
      padding:6px 25px 6px 10px;
      border-radius: 10px 0 0 10px;
      // margin-top: -6px;
  }
  .modify-container .flex-child-left ion-textarea{
    background-color: var(--kargoroo-single-item-quest-modify-bg);
    height: 35px !important;
    padding: 6px 25px 6px 10px;
    border-radius: 10px 0 0 10px;
  }
  .modify-container .flex-child-left ion-textarea textarea{
    line-height: 24px;
  }
    .modify-container .flex-child-right {
      position: absolute;
      z-index: 3;
      right: 0;
      top: 0;
      height: 35px !important;
    }
    .pickup_item_text{
      margin-bottom: 10px !important;
    }
    .deliveryselection{
      background:var(--kargoroo-single-quest-ion-item-bg);
      color: white;
      text-align: center;
      border-radius: 10px;
      --placeholder-opacity: 1 !important;
    }
    .formErrorMsg {
      font-size: 10px;
      font-family: 'Montserrat-Regular';
      color: red;
      margin: 0px;
      padding: 0px;
      display: flex;
      margin-left: 20px;
      padding-top: 5px;
    }
    .deletebtn {
      // --background: #e7e6e6;
      width: 100%;
      color: white;
      height: 40px;
      font-weight: bold;
      margin-bottom: 40px;
      font-family: 'Montserrat-Regular';
      font-size: 16px;
    }
     ion-select::part(icon) {
      display: none;
     }
     .selectlabeldisable{
       z-index: 99 !important;
     }
     .text-desc-err{
       margin: 0px !important;
     }
}

.pickLocationTypeSelector .alert-wrapper {
  min-width: 85%;
}

.deliverToSelector .alert-wrapper {
  min-width: 85%;
}
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .FullQuest{
      background-color: var(--kargoroo-card-bg-color);
      padding-top: 10px;
    }
    .FullQuest .questcontainer {
      width: 550px;
      margin: 0 auto !important;
    }
    .pickLocationTypeSelector .alert-wrapper {
      min-width: 30%;
    }
    .deliverToSelector .alert-wrapper {
      min-width: 30%;
    }   
  }

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .FullQuest{
    background-color: var(--kargoroo-card-bg-color);
    padding-top: 10px;
  }
  .FullQuest .questcontainer {
    width: 550px;
    margin: 0 auto !important;
  }
  .pickLocationTypeSelector .alert-wrapper {
    min-width: 30%;
  }
  .deliverToSelector .alert-wrapper {
    min-width: 30%;
  }
}

@media screen and (min-width: 1601px) {
  .FullQuest{
    background-color: var(--kargoroo-card-bg-color);
    padding-top: 10px;
  }
  .FullQuest .questcontainer {
    width: 550px;
    margin: 0 auto !important;
  }
  .pickLocationTypeSelector .alert-wrapper {
    min-width: 30%;
  }
  .deliverToSelector .alert-wrapper {
    min-width: 30%;
  }
}