.ProgressBarStepper {
    width: 100%;
    height: 20px;
    margin: 12px 32px 4px 32px;

    .RSPBprogressBar {
        height: 4px;
        background-color: #c7ddca;
    }

    .RSPBprogression {
        background-color: #62CB91;
    }

    .indexedStep {
        color: white;
        width: 14px;
        height: 14px;
        font-size: 12px;
        background-color: #dbdbdb;
        background-color: #c7ddca;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .dot {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 7px;
            height: 7px;
            background-color: #ffffffbb;
            border-radius: 50%;
        }

        ion-icon {
            font-size: 10px;
        }
    }
      
    .indexedStep.accomplished {          
        transition: 0.3s ease-in-out;
            background-color: #62CB91;    

            width: 18px;
            height: 18px;

            ion-icon {
                font-weight: bold;
                font-size: 10px;
                --ionicon-stroke-width: 40px;
                display: none;
            }
            
            ion-icon {
                display: flex;
                color: white;
                font-size: 14px;
                --ionicon-stroke-width: 60px;
            }
      }

    .indexedStep.active {
        transition: 0.3s ease-in-out;

        width: 14px;
        height: 14px;

        ion-icon {
            display: none;
        }

        .dot {
            background-color: white;
        }

        
    }
}