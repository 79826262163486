.currentLocationModal {
  font-family: 'Montserrat-Regular';
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 100px 0;

  z-index: 30000!important;

  &::part(content) {
    --border-color: #044800;
    --border-style: solid;
    --border-width: 4px;
    --border-radius: 25px;
    // height: 350px;
    height: 90%;
    min-height: 400px;
    width: 95%;
    min-width: 300px;
    z-index: 1000000;

  }

  &::part(backdrop) {
    --backdrop-opacity: 0.5;
    
  }

  .panButtons {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    top: 100px;
    right: 3px;

    .pickupLocation {
      background-color: #0592e4;
      border: 2px solid #004094;
    }

    .deliveryLocation {
      background-color: #df2323;
      border: 2px solid #8d0303;
    }

    .panButtonContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 4px;
      height: 48px;
      padding: 4px 10px 4px 10px;
      color: white;
      border-radius: 50px;
      font-size: 14px;
      font-weight: bold;

      ion-icon {
        font-size: 18px;
      }
    }
  }

  .deliveryInfoContainer {
    position: absolute;
    width: 100%;
    min-height: 100px;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    color: white;
    padding: 8px;

    .pickupLocationInfoContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
      background-color: #0592e4;
      border: 2px solid #004094;
      border-radius: 15px;
      margin: 2px;

      h5 {
        margin: 4px;
        padding: 0;
        font-weight: bold;
        font-size: 18px;
      }

      p {
        margin: 0;
        padding: 0;
        font-size: 15px;
      }
    }

    .deliveryLocationInfoContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
      background-color: #df2323;
      border: 2px solid #8d0303;
      border-radius: 15px;
      margin: 2px;

      h5 {
        margin: 4px;
        padding: 0;
        font-weight: bold;
        font-size: 18px;
      }

      p {
        margin: 0;
        padding: 0;
        font-size: 15px;
      }
    }
  }

  &::part(backdrop) {
    --backdrop-opacity: 0.6;
  }

  .inputElements {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .actionButtons {
    position: absolute;
    bottom: 20px;
    width: 100%;
    z-index: 20;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 2px 8px 4px 8px;

      ion-button {
        --border-color: #044800;
        --border-style: solid;
        --border-width: 2px;
        --border-radius: 15px;
        flex-grow: 1;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .currentLocationModal {
    &::part(content) {
      width: 350px;
      min-width: 550px;
    }
    .actionButtons {
      width: 200px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
       transform: translateX(-50%);
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .currentLocationModal {
    &::part(content) {
      width: 350px;
      min-width: 550px;
    }
    .actionButtons {
      width: 200px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
       transform: translateX(-50%);
    }
  }
}

@media screen and (min-width: 1601px) {
  .currentLocationModal {
    &::part(content) {
      width: 350px;
      min-width: 550px;
    }
    .actionButtons {
      width: 200px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
       transform: translateX(-50%);
    }
  }
}
.addresscursor {
  cursor: pointer;
}

