.myRQ_CardTop {
  margin: 0;
  padding: 0;
  color: var(--kargoroo-card-text-color);
  font-family: 'Montserrat-Regular';
  text-align: center;

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .rqImg {
      height: 54px;
      width: 53px;
      margin: 3px;
      margin-right: 8px;
      border: 1px solid #084400;
      border-radius: 50px;
    }

    .titleComponent {
      display: flex;
      flex-direction: column;
      text-align: left;

      .title {
        margin: 0;
        padding: 0;
        align-self: flex-start;
        font-size: 16px;
      }
      .costs {
        margin: 0;
        padding: 3px 0 0 0;
        align-self: flex-start;
        font-size: 14px;
        font-weight: bold;
        color: var(--kargoroo-card-price-text-color);
      }
    }

    .statusComponent {
      margin-left: auto;
      padding-top: 3px;
      padding-bottom: 3px;
      width: 90px;
      flex-shrink: 0;

      .title {
        margin: 0;
        padding: 0;
        font-size: 13px;
        color: var(--kargoroo-card-status-text-color)
      }

      .status {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .earnComponent {
      margin-left: auto;
      width: 90px;
      flex-shrink: 0;

      .title {
        margin: 0;
        padding: 0;
        font-size: 13px;
        color: var(--kargoroo-my-req-del-text-header);
      }

      .delivCost {
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
