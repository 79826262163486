.saveRQBtn {
  position: relative;
  top: 1px;

  .saveContainer {
    display: flex;
    flex-direction: row;

    p {
      font-size: 14px;
      color: var(--kargoroo-my-req-del-text);
      font-family: 'Montserrat-Regular';
      position: relative;
      top: 2px;
      right: 2px;
      // margin-top: 2px;
    }
  }

  .icon {
    // float: right;
    font-size: 24px;
    color: rgb(231, 62, 62);
  }
}
