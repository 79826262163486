.RooQuestChecklistItemsList {
    margin-top: 18px;

    .sectionTitle {
        span {
            color: var(--rooquest-check-list-section-title);
        }
    }

    .RooQuestCheckListItem {
        display: flex;
        flex-direction: row;
        align-items: center;

        .hidden-checkbox{
            display: flex;
            flex-shrink: 0;
            height: 28px;
            width: 28px;
            margin: 0 0 0 12px;
            visibility:hidden;
        }

        ion-checkbox {
            display: flex;
            flex-shrink: 0;
            height: 28px;
            width: 28px;
            margin: 0 0 0 12px;
        }

        .editBtn {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: var(--mrq-button-secondary-background);
            border-radius: 50px;
            padding: 12px;

            width: 36px;
            height: 36px;

            ion-icon {
                font-size: 32px;
                color: black;
            }

            &:hover {
                cursor: pointer;
            }

            &:active {
                background: var(--mrq-button-secondary-background-active);
            }
        }
    }

    ion-accordion {
        border-radius: 6px;
        

        ion-icon {
            font-size: 18px;
            color: grey;
        }

        ion-item {
            --border-color: #a3a3a3;
        }

        .cost-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 4px;
            border-bottom: 2px solid #6b6b6b;


            .maininfo {
                font-size: 13px;
                color: var(--mrq-add-items-accent);
                font-weight: bold;
                margin-bottom: 12px;
                margin: 0;
                text-align: center;
            }

            .subinfo {
                font-size: 12px;
                color: var(--mrq-add-items-secondary-text);
                color: black;
                font-weight: bold;
                margin: 0;
                text-align: center;

            }

        }
    }

    .infoRQ-item-info {
        display: flex;
        flex-direction: column;
        padding: 6px 2px;
        width: 100%;

        .item-title {
            font-weight: bold;
        }

        .checkbox-space { 
            display: flex;
            flex-shrink: 0;
            height: 28px;
            width: 28px;
            margin: 0 0 0 12px;
        }

        .cost {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            font-size: 14px;
            font-family: 'Poppins-SemiBold';
            color: #808080;
            color: var(--mrq-add-items-secondary-text-light);

            .total {
                font-weight: bold;
            }
        }
    }

    .infoRQ-item-moreinfo {
        border-bottom: 2px solid #6b6b6b;

        .infoRQ-moreinfo-section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 8px;
            padding: 8px;
            border-bottom: 1px solid #e6e6e6;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                border-bottom: none;
            }

            color: black;
            

            .infoRQ-sectionTitle {
                font-size: 14px;
                font-weight: bold;
                // font-family: 'Poppins-SemiBold';
                color: var(--mrq-add-items-secondary-text-light);
                margin-bottom: 4px;
            }

            .infoRQ-sectionContent {
                font-size: 14px;
                color: var(--infoRQ-sectionContent-color);
            }

            .itemCost {
                margin-left: 12px;
            }

            .photosContainer {
                display: flex;
                flex-direction: row;
                width: 100%;
                overflow-x: scroll;

                img {
                    width: 56px;
                    height: 56px;
                    margin-right: 8px;
                    object-fit: cover;
                    border: 1px solid #5f5f5f;
                }
            }
        }
    }

}