/*
   * iOS red Theme
   * -------------------------------------------
   */
   body.red {
     
  --ion-color-kargoroo_green: red;
  --ion-color-kargoroo_green-rgb: 0, 189, 0;
  --ion-color-kargoroo_green-contrast: #ffffff;
  --ion-color-kargoroo_green-contrast-rgb: 255, 255, 255;
  --ion-color-kargoroo_green-shade: red;
  --ion-color-kargoroo_green-tint: red;

    --ion-color-primary: #FFFF00;
    --ion-color-primary-rgb: 255,255,0;
    --ion-color-primary-contrast: black;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #e0e000;
    --ion-color-primary-tint: #ffff1a;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;
  
    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;
  
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
  
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
  
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
  
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
  
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
  
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-color-nextgreen: #830202;
    --ion-color-nextgreen-rgb: 0,176,80;
    --ion-color-nextgreen-contrast: #ffffff;
    --ion-color-nextgreen-contrast-rgb: 255,255,255;
    --ion-color-nextgreen-shade: #830202;
    --ion-color-nextgreen-tint: #830202;

    --kargoroo-background-color: #ef0b0b;
    --kargoroo-background-color-shade: #ef0b0b;
    --kargoroo-dark-background-color: #830202;
    --kargoroo-icon-text-color:black;
   }
   body.red .questcontainer ion-item {
    --background: white;
  }
    .ios body.red {
   --ion-background-color: #ef0b0b;
   --ion-background-color-rgb: 239,11,11;
  
   --ion-text-color: #000000;
   --ion-text-color-rgb: 0,0,0;
 
   --ion-color-step-50: #e30a0a;
   --ion-color-step-100: #d70a0a;
   --ion-color-step-150: #cb0909;
   --ion-color-step-200: #bf0909;
   --ion-color-step-250: #b30808;
   --ion-color-step-300: #a70808;
   --ion-color-step-350: #9b0707;
   --ion-color-step-400: #8f0707;
   --ion-color-step-450: #830606;
   --ion-color-step-500: #780606;
   --ion-color-step-550: #6c0505;
   --ion-color-step-600: #600404;
   --ion-color-step-650: #540404;
   --ion-color-step-700: #480303;
   --ion-color-step-750: #3c0303;
   --ion-color-step-800: #300202;
   --ion-color-step-850: #240202;
   --ion-color-step-900: #180101;
   --ion-color-step-950: #0c0101;
 }

 .md body.dark {
    --ion-background-color: #ef0b0b;
    --ion-background-color-rgb: 239,11,11;
   
    --ion-text-color: #000000;
    --ion-text-color-rgb: 0,0,0;
  
    --ion-color-step-50: #e30a0a;
    --ion-color-step-100: #d70a0a;
    --ion-color-step-150: #cb0909;
    --ion-color-step-200: #bf0909;
    --ion-color-step-250: #b30808;
    --ion-color-step-300: #a70808;
    --ion-color-step-350: #9b0707;
    --ion-color-step-400: #8f0707;
    --ion-color-step-450: #830606;
    --ion-color-step-500: #780606;
    --ion-color-step-550: #6c0505;
    --ion-color-step-600: #600404;
    --ion-color-step-650: #540404;
    --ion-color-step-700: #480303;
    --ion-color-step-750: #3c0303;
    --ion-color-step-800: #300202;
    --ion-color-step-850: #240202;
    --ion-color-step-900: #180101;
    --ion-color-step-950: #0c0101;
  }
 