.singleitemconfirm ion-content {
    // --overflow: hidden !important;
    --background: var(--kargoroo-dark-background-color);
    .confirmbtn{
        // --background: #00bd00;
        width: 100%;
        color: white;
        height: 40px;
        // margin-top: -250px;

    }
}
