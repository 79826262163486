.CloseWindow {
  height: 100%;
  width: 100%;
  background-color: white;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    h1 {
      margin: 0;
      margin-top: 8px;
      margin-bottom: 120px;
    }

    ion-icon {
      font-size: 44px;
    }
  }
}

ion-content {
  --overflow: hidden;
}

.landingPage {
  ion-content {
    --padding-bottom: 56px;
    --overflow: hidden;
  }

  .landingHeader {
    position: fixed;
    top: 0px;
    // padding-top: 25px;
    z-index: 100;
    // height: 60px;
    width: 100%;
    text-align: center;

    #logo {
      height: 70px;
      padding-top: 10px;
      -webkit-transition: transform 0.8s;
      transition: transform 0.8s;
      cursor: pointer;
    }
    .rotate {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }

    .menuButton {
      margin: 18px 8px 0px 12px;
      padding-top: 6px;
      position: fixed;
      left: 0;
      background-color: var(--kargoroo-background-color,#00bd00);
      width: 44px;
      height: 44px;
      border-radius: 25px;
      font-size: 32px;
      cursor: pointer;
    }

    ion-menu-button::part(icon) {
      color: white;
      margin-top: -5px;
    }
    

    .plusButton {
      margin: 18px 12px 0px 8px;
      padding-top: 6px;
      position: fixed;
      right: 0;
      top: 0;
      background-color: var(--kargoroo-background-color,#00bd00);
      width: 44px;
      height: 44px;
      border-radius: 25px;
      font-size: 32px;
      cursor: pointer;
      ion-icon {
        color: white;
        --ionicon-stroke-width: 38px;
      }

      .notif_unreadBadge {
        // height: 14px;
        font-size: 11px;
        // width: 14px;
        // background-color: red;
        position: absolute;
        border-radius: 25px;
        top: -4px;
        right: -7px;
        border: white 1px solid;
        box-shadow: 0 8px 6px -6px black;
      }
    }
  }

  .landingContent {
    position: fixed;
    z-index: 100;

    // -webkit-transition: 0.3s;
    // -moz-transition: 0.3s;
    // -o-transition: 0.3s;
    // transition: 0.3s;
    // transition-timing-function: ease-in;
    // opacity: 1;
    // visibility: visible;

    .actionCardContainer {
      text-align: center;
      width: 100%;
      position: fixed;
      bottom: 80px;
      // max-width: 600px;
      // margin: auto;

      .updateContainer {
        background-color: var(--kargoroo-dark-background-color,#084400);
        color: white;
        border-radius: 12px;
        margin: 8px 8px 8px 8px;
        font-weight: bold;

        .text {
          padding: 8px 0px 8px 0px;
          p {
            font-size: 14px;
            margin: 0;
            padding-top: 0;
          }
        }
      }

      .actionButtonContainer {
        display: flex;
        flex-direction: row;
        padding: 0px 4px 0px 4px;

        ion-button {
          --background: var(--kargoroo-background-color,#00bd00);
          --border-color: #084400;
          --border-radius: 12px;
          --border-width: 3px;
          --border-style: solid;
          flex-grow: 1;
          height: 70px;
          margin: 0px 4px 8px 4px;
          width: 50%;

          .buttonText {
            text-transform: none;
            font-family: 'Montserrat-Regular';

            h5 {
              font-size: 18px;
              font-weight: bold;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
