.PostRQAdditionalNote {
    backdrop-filter: blur(3px);

    --min-width: 80%;
    --max-width: 800px;
    --min-height: 80%;
    
    &::part(background) {
        border-radius: 20px;
    }

    &::part(handle) {
        background: var(--mrq-green-light);
        width: 100px;
        height: 6px;
    }

    &::part(content) {
        border-radius: 0px;
        box-shadow: 0px 0px 20px 5px rgba(5, 5, 5, 0.32);
        background-color: white;
    }

    ion-content {
        --background: var(--mrq-main-background) !important;
    }

    .addItemContent {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 16px;
        font-family: 'Montserrat-Regular';
        align-items: center;


        .closeBtn {
            position: absolute;
            top: 6px;
            right: 8px;
            font-size: 32px;
            color: rgb(150, 150, 150);
        }


        .addItemTitle {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            p {            
                margin: 18px 0 8px 0;    
                font-size: 20px;
                font-weight: bold;
                color: var(--kargoroo-text) !important;
            }
        }

        ::-webkit-scrollbar {
            display: block;
            width: 18px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 20px;
            border: 7px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 20px;
            border: 7px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }
        

        .inputs-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow-y: auto;            
            
            
            .inputs {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
            }

            input, textarea {
                font-size: 16px;
                border: 1px rgb(214, 214, 214) solid;
                border-radius: 8px;
                padding: 8px;
                width: 100%;
                height: 40px;
                color: rgb(43, 43, 43);
                background-color: rgb(237, 240, 245);

                &.error {
                    border: 1.5px rgb(253, 141, 141) solid;
                }
            }

            textarea {
                height: 128px;
            }

            .input-label {
                margin: 0;
                font-size: 14px;
                color: var(--mrq-add-items-secondary-text) !important;
                font-weight: bold;
                margin-bottom: 8px;

                span {
                    color: red;
                }

                .errorMsg {
                    font-size: 13px;
                }
            }   
            
            .input-item {
                margin-top: 16px;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        
        .btnContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;   
            padding-bottom: 60px;         
        }

        .addItemBtn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-radius: 9px;
            font-family: 'Poppins';
            color: white;
            background: var(--mrq-button-main-background);
            width: 180px;
            margin: 16px 4px 24px 4px;
            border-radius: 50px;
            align-self: center;
            
            ion-icon {
                position: relative;
                left: 4px;
                bottom: 1px;
                font-size: 18px;
                --ionicon-stroke-width: 50px;
                color: var(--addItemBtn-button-test-color);
            }

            &:active {
                background: var(--mrq-button-main-background-active)
            }

            p {
                margin: 12px;
                font-size: 14px;
                font-family: 'Poppins';
                font-weight: bold;
                color: var(--addItemBtn-button-test-color)
            }
        }

    }
}