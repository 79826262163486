.cart-history-modal{

	.modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background-color: #00000055;
		z-index: 10;
	}
	
	.shoppingCart {
		width: 60%;
		height: 90%;
		margin: 2rem auto;
		background-color: #eee;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		position: relative;
	}
	
	.shoppingCart .header {
		display: flex;
		background-color: #ccc;
		padding: 10px 20px;
		justify-content: space-between;
	}
	
	
	
	.closeButton{
		width: 33px;
		height: 32px;
		border-radius: 17px;
		font-size: 32px;
		cursor: pointer;
		margin: auto 0;
		  ion-icon { 
			color: #737373;
		  }
	   }
	
	.empty-text {
		display: block;
		padding: 2rem;
		margin: auto;
	}
	
	.cart-products {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 1rem 0;
		overflow-y: scroll;
		// border:2px solid purple;
		min-height: 70%;
		&::-webkit-scrollbar {
			display: block;
			width: 11px;
		}
	
		/* Track */
		&::-webkit-scrollbar-track {
			background: #e7e7e7;
			border-radius: 20px;
			border: 4px solid rgba(0, 0, 0, 0);
			background-clip: padding-box;
		}
	
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: rgb(126, 126, 126);
			border-radius: 20px;
			border: 4px solid rgba(0, 0, 0, 0);
			background-clip: padding-box;
		}
	}
	
	.cart-product {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		//come back here
		// align-items: center;
		width: 100%;
		background-color: #fff;
		padding: 10px 10px;
		border: 3px solid #eee;
		// border:1px solid blue;
	
	}
	
	.product-info {
		// flex-basis: 50%;
		//display: flex;
		flex-direction: column;
		color: var(--kargoroo-modal-text);
		// border:1px solid green;
		width: calc(100%/2.75); /* Setting the width of columns at one third each */
		
		justify-content: center !important;
		
	}
	
	.product-info h3 {
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0px !important;
		margin-block-start: 0px !important;
	}
	
	
	.cart-products img {
		max-width: 100px;
		max-height: 80px;
		background-color: #fff;
		// border:1px solid red;
	}
	
	.checkout-btn {
		// background-color: #065a82;
		padding: 10px 20px;
		font-size: 1rem;
		color: #fff;
		align-self: center;
		margin: 2rem 0;
		
		background: #00bd00;
		border-radius: 60px;
		border-width: 1px;
		height: 50px;
		width: 140px;
		text-transform: none;
		// font-size: 20px;
	}
	
	
	.stepper{
			display: flex;
			flex-direction: column;
			align-items: center;
			// justify-content: center;
			// border: 1px solid red;
			.label{
				// border:1px solid blue;
				margin-top: 0px;
			}
			ion-label{
				color:#000;
				font-size: 1rem;
				// border:2px slategray;
			}
			// width: 3rem;
		  
		  .stepper-buttons {
			display: flex;
			align-items: center;
			justify-content: center;
			// border:1px solid red;
			height: 100%;
			// margin-left: 10px;
		  }
		  
		  .stepper-button {
			--background: #dedede;
			--box-shadow: none;
			--border-radius: 50%;
			--padding-start: 0;
			--padding-end: 0;
			--color: #000;
			// border: 2px solid var(--ion-color-primary);
			// width: 15px;
			height: 2em;
			// font-size: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
		  }
		  
		//   .stepper-button:hover {
			
		//   }
		  
		  .stepper-count {
			font-size: 20px;
			margin: 0 10px;
		  }
		  
	}
}


	  /* Media queries for smaller screens */
	  @media (max-width: 480px) {

		.cart-history-modal{
			.shoppingCart {
				width: 95%;
			}
		
			.cart-products img {
				width: 60px;
				background-color: #fff;
			}
		
			.checkbox{
				display: flex;
				align-items: center;
				// border:1px solid red;
				height:46%;
				padding-top: 15px;
				width: 45px;
				justify-content: end;
			}
		
			.stepper{
				display: flex;
				flex-direction: column;
				align-items: center;
				// border: 1px solid purple;
				ion-label{
					color:#000;
					border:2px slategray;
				}
				// width: 3rem;
			  
			  .stepper-buttons {
				display: flex;
				align-items: center;
				z-index: 9999;
				// margin-left: 10px;
			  }
			  
			  .stepper-button {
				--background: #dedede;
				--box-shadow: none;
				--border-radius: 50%;
				--padding-start: 0;
				--padding-end: 0;
				--color: #000;
				// border: 2px solid var(--ion-color-primary);
				// width: 15px;
				height: 2em;
				// font-size: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
			  }
		}

		}
	  
	  }


  