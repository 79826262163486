.ActivationCodeModal {
    backdrop-filter: blur(3px);

    --min-width: 80%;
    --max-width: 800px;
    --min-height: 55%;
    --height: 70%;
    --width: 100%;
    
    &::part(background) {
        border-radius: 20px;
    }

    &::part(handle) {
        background: var(--mrq-green-light);
        width: 100px;
        height: 6px;
    }

    &::part(content) {
        border-radius: 0px;
        box-shadow: 0px 0px 20px 5px rgba(5, 5, 5, 0.32);
        background-color: white;
    }

    ion-content {
        --background: white;
    }

    .ActivationCodeModalContent {

        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        font-family: 'Poppins';
        background-color: var(--promo-page-color);

        .header {
            height: 50px;
            margin-bottom: 4px;


            .closeBtn {
                position: absolute;
                top: 6px;
                right: 8px;
                font-size: 32px;
                color: rgb(150, 150, 150);
            }

            .title {
                margin-top: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
    
                p {            
                    margin: 18px 0 8px 0;    
                    font-size: 20px;
                    font-weight: bold;
                    color: rgb(37, 37, 37);
                }
            }
        }
    
        .main {
            display: flex;
            flex-direction: column;
            height: 100px;
            width: 100%;
            flex-grow: 1;

            overflow-y: auto;

            .description {
                text-align: center;
                padding: 12px 32px;
                color: var(--mrq-grey);
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                input {
                    background-color: #ececec;
                    border-radius: 8px;
                    max-width: 300px;
                    color: black;
                    text-align: center;
                    position: relative;
                    padding: 8px 16px;
                    text-decoration: none;
                    outline: none;
                    border: 1px solid rgb(68, 68, 68);
                    font-size: 20px;

                    &:focus {
                        outline: var(--mrq-green-light);
                    }

                }
            }

            .inputsContainer {
                padding: 0px 32px 12px 32px;


                .inputSection {
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    .question {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-end;
                        color: var(--mrq-grey-dark);
                        font-weight: bold;
                        font-size: 16px;

                        p {
                            margin: 0;
                        }

                        .subInfo {
                            font-size: 13px;
                            margin-left: 2px;
                        }

                    }

                }

            }
            

            
        }
    
        .footer {
            display: flex;
            // position: absolute;
            bottom: 12px;
            height: 50px;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-bottom: 18px;

            .acknowledgeBtn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: var(--mrq-green);
                color: white;
                border-radius: 50px;
                font-weight: bold;
                width: 70%;
                max-width: 350px;
                height: 50px;


                &:active {
                    background-color: var(--mrq-green-dark);
                }

                ion-spinner {
                    color: black;
                    height: 50px;
                }

                &.loading {
                    background-color: #cfcfcf;
                }
            }
        }
    }  
}
