.PostRooQuestConfirmation {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0 36px 0 36px;
    align-items: center;
    overflow-y: auto;
    .checkmarkContainer {
        margin-top: 12px;
        height: 128px;
        width: 128px;
        border-radius: 50%;
        background: radial-gradient(circle at center, rgb(104, 243, 104) 0, rgba(0, 128, 0, 0.021) 100%);
        display: flex;
        align-items: center;
        justify-content: center;

        .checkmark {
            height: 96px;
            width: 96px;
            border-radius: 50%;
            background-color: var(--mrq-green);
            display: flex;
            align-items: center;
            justify-content: center;

            ion-icon {
                font-size: 58px;
                color: white;
            }

        }
    }

    .text {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-family: 'Poppins';

        h3 {
            font-size: 24px;
            color: var(--kargoroo-text);
        }

        h5 {
            font-size: 18px;
            color: grey;
        }
    }

    .confirmationCode {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-family: 'Poppins';

        h5 {
            font-size: 17px;
            color: var(--kargoroo-text)
        }

        p {
            font-size: 16px;
            color: grey;
        }
    }   
}