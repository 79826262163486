.VerticalAddressComponent {
    .route-item {
        display: flex;
        flex-direction: row;

        .iconContainer {
            position: relative;
            margin-right: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;

            ion-icon {
                display: flex;
                align-items: center;
                width: 24px;
                font-size: 18px;
                color: var(--mrq-green-dark);
                flex-shrink: 0;
            }

            .dottedLine {
                position: relative;
                top: 4px;
                height: 100%;
                border-left: 2px dashed #d6d6d6;                            
            }
        }                    

        p {
            margin: 0;
            font-size: 14px;
            color: var(--mrq-add-items-secondary-text);

            span {
                color: --var(kargoroo-text);
            }
        }

        &:first-child {
            margin-bottom: 8px;
        }
    }
}