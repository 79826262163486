.delivererSetupModalBody {
  text-align: center;
  overflow: scroll;
  margin-bottom: 80px;

  p {
    margin: 16px;
    padding: 4px 0 4px 0;
    font-size: 16.5px;
  }

  .stripeButtonContainer {
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    cursor: pointer;
    img {
      width: 75%;
    }

    .completeVerify {
      margin-top: 10px;
      color: #fff;
      margin: 0px 10% 5px 10%;
      font-weight: bold;
      background-color: #3fdb3f;
      border-radius: 12px;
      padding: 2px 4px;
      border: none;
      cursor: pointer;
      p {
        padding: 0px;
        margin: 10px;
        font-size: 20px;
      }
    }
  }
}
