.paymentModal {
  text-align: center;
  background-color: #f5f0f0;

  ion-content {
    --padding-bottom: 110px;
    --background: #f5f0f0;
  }

  .paymentModalContent {
    background-color: #f5f0f0;
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 0px;

    .paymentModal-header-container {
      position: fixed;
      top: 0px;
      background-color: #084400;
      border-radius: 0 0 25px 25px;
      height: 78px;
      width: 100%;
      z-index: 2;

      #paymentModal-title {
        position: relative;
        top: 10px;
        left: 20px;
        font-family: 'Montserrat-Regular';
        font-weight: bold;
        color: white;
      }
    }
    .paymentModalBodyContent {
      position: relative;
      top: 88px;
      height: 100%;
      z-index: 1;
      // background-color: #f5f0f0;

      //   padding-bottom: 100px;

      .paymentModal-body-container {
        color: white;
        background-color: #00bd00;
        border-radius: 25px 25px 25px 25px;
        width: 95%;
        display: inline-block;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2),
          0 4px 12px 0 rgba(0, 0, 0, 0.2);
        margin-bottom: 12px;

        &.errors {
          background-color: #df4343;

          .errorMsg {
            padding: 8px 0 8px 0;
          }
        }

        .successMsg {
          padding: 8px 0 8px 0;
        }

        .paymentModal-body-contentcontainer {
          background-color: white;
          border-radius: 20px 20px 20px 20px;
          margin: 4px 4px 4px 4px;
          position: relative;
          //   padding-top: 6px;
          // bottom: 0px;

          .paymentModal-titleContainer {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;

            #paymentModal-rqImg {
              margin: 8px;
              height: 48px;
              width: 48px;
              border: 2px solid #084400;
              border-radius: 25px;
              object-fit: cover;
            }

            .paymentModal-rqTitle {
              color: black;
              position: relative;
              font-family: 'Montserrat-Regular';
              font-size: 20px;
              font-weight: bold;
            }
          }
          .paymentModal-location {
            color: black;
            font-size: 16px;
            font-weight: bold;
            font-family: 'Montserrat-Regular';
            text-align: start;
            padding-left: 24px;
            padding-bottom: 8px;
            margin: 0px;

            span {
              color: rgb(110, 110, 110);
              padding-right: 8px;
            }
          }

          .errorContainer {
            color: black;
            font-family: 'Montserrat-Regular';
            margin: 0 4px 0 4px;
          }

          .successContainer {
            color: black;
            font-family: 'Montserrat-Regular';
            margin: 0 4px 0 4px;
          }
        }

        .paymentModal-paymentContainer {
          display: flex;
          flex-direction: row;
          padding-top: 6px;

          .paymentItemLabels {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            h5 {
              color: rgb(110, 110, 110);
            }
          }

          .paymentItemPrices {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          .paymentModal-paymentDetails {
            color: black;
            font-size: 16px;
            font-weight: bold;
            font-family: 'Montserrat-Regular';
            text-align: start;
            padding-left: 24px;
            padding-bottom: 8px;
            padding-top: 4px;
            margin: 0px;
          }
          .total {
            font-size: 18px;
          }
        }

        .paymentModal-sectionTitle {
          position: relative;
          font-family: 'Montserrat-Regular';
          font-weight: bold;
        }
      }

      .paymentModal-sectionInfo {
        text-align: left;
        font-family: 'Montserrat-Regular';
        padding-left: 12px;
      }

      .cardElementContainer {
        padding: 20px 20px 18px 20px;
      }
      ion-button {
        --background: #118f00;
      }
    }
  }
}
