
.reviewModal{

  // height : 300px;
  &::part(content) {
    // --min-height: 490px;
    --height: auto;
    --border-color: #044800;
    --border-style: solid;
    --border-width: 2px;
    --border-radius: 25px;
    //  margin: 10px;
  }

  

  .card-body {
    text-align: center;
  }
  .rating{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // margin-left: 44px
  }

  .cross {
    padding: 10px;
  cursor: pointer;
  font-size: 23px;
  text-align: right;
  color: black;
  }
  
  .cross i{
  margin-top: -5px;
  cursor: pointer;
  }
  .visible{
    height: 90vh;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: block;
      width: 14px;
  }


  /* Track */
  &::-webkit-scrollbar-track {
      background: #e7e7e7;
      border-radius: 20px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
      background: rgb(126, 126, 126);
      border-radius: 20px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
  }

  }
  
  .comment-area textarea {
  resize: none;
  width: 70%;
  height: 150px;
  color: var(--kargoroo-card-text-color);
  background-color: var(--kargoroo-card-bg-color);
  // border: 1px solid #ff0000
  }
 
  .send-button {
    // text-center mt-4
    text-align: center;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    // margin-top: 10px ;
  }

  .send {
    color: #fff;
    background-color: #ff0000;
    border-color: #ff0000
}

.send:hover {
    color: #fff;
    background-color: #f50202;
    border-color: #f50202
}
  
  .comment-box {
    // border: 1px solid purple;
    // width: 50px;
    h4 {
      color: var(--kargoroo-text);
    }
  }

}
