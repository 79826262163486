.MyRooQuestDelivery {
    position: relative;
    width: 100%;
    height: 100%;

    overflow-y: auto;
    padding-bottom: 80px;
    display: flex;
    flex-direction : column;
    .footer{
        position: relative;
    }
    .currentStep{
        height: 100%;
    }

    &::-webkit-scrollbar {
        display: block;
        width: 14px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #e7e7e7;
        border-radius: 20px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(190, 190, 190);
        border-radius: 20px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
    

    .DeliveryStepper {
        position: relative;
        display: flex;
        width: 100%;
        // border: 1px solid red;

        .steps {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            align-items: center;
            // margin: 0px 16px 2px 16px;
            // border: 1px solid blue;

            .step {
                display: flex;
                flex-grow: 1;
                min-width: 40px;
                height: 6px;
                border-radius: 4px;
                background: #d1d1d1;
                margin: 6px;

                &.selected {
                    background: var(--mrq-add-items-accent);
                }

                &.current {
                    background-color: #222;
                    animation-name: color;
                    animation-duration: 1.5s;
                    animation-iteration-count: infinite;

                    @keyframes color {
                        0%, 100% {
                          background-color: var(--mrq-add-items-accent);
                        }
                        40% {
                          background-color: #d6ebce;
                        }
                      }
                }
                
            }
        }
    }

    .estimatedDelivery {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 12px 18px 0px 18px;

        p {
            font-size: 13px;
            color: #818181;
            margin: 0;
            text-align: center;
        }

        .time {
            color: var(--mrq-green);
            font-weight: bold;
            font-size: 20px;
            margin: 0;
            text-align: center;
        }
    }

    .deliveryMessage {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 18px 18px 12px 18px;

        p {
            font-size: 15px;
            color: var(--kargoroo-rooquest-text);
            font-weight: bold;
            margin: 0;
            text-align: center;
        }
    }

    .deliveryImages {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .deliveryImage {
            width: 54px;
            height: 54px;
            margin: 6px 6px;
            border-radius: 4px;
            background-color: #d1d1d1;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

            }
        }
    }

    .deliveryImg {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 54px 18px 12px 18px;

        img {
            max-width: 300px;
            max-height: 300px;
            width: 65%;           
        }

        &.small {
            padding: 4px 18px 8px 18px;
            img {
                max-width: 128px;
                max-height: 128px;
                width: 50%;
            }
        }

        p {
            margin-top: 8px;
            font-weight: bold;
            color: #7e7e7e;
            font-size: 16px;
        }
    }

    .RQDeliv-deliveryOffers {
        margin-top: 18px;
        padding: 4px 16px;
        position: relative;
        width: 100%;
        

        .offersContainer {
            display: flex;
            flex-direction: column;     
            width: 100%;  
            padding: 4px;             
        }
    }


    .collectingRooQuestItems {
        padding: 28px 18px 4px 18px;

        .collectingRQItems {
            padding: 6px;
        }

        .progressBarContainer{
            height: 20;
             width: 90%;
             background-color:#e0e0de ;
             border-radius: 50px;
             margin: auto;
             margin-top: 10px;
        }
        .progressBarText{
            // padding: 5;
            padding-right: 5px;
            color: var(--progress-bar-text);
            font-weight: bold;
            font-size: 15px;
        }



        .collectingRQItem {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 12px 8px;
            border-bottom: 1px rgb(226, 226, 226) solid;

            &:last-child {
                border-bottom: none;
            }
        }

        .rq-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            p {
                font-size: 16px;
                color: var(--collecting-items-color);
                margin: 0;
                text-align: left;
            }

            ion-icon {
                position: relative;
                font-size: 22px;
                margin-right: 8px;

                &.collected {
                    color: var(--mrq-green);
                }
                &.uncollected {
                    color:#d8d8d8;
                }
                &.problem {
                    color: var(--mrq-red);
                }                
            }

            .itemCollectionStatus {
                p {
                    position: relative;
                    top: -2px;
                    font-size: 13px;
                    color: var(--mrq-red);
                    margin: 0;
                    text-align: left;
                }
            }

        }
        .rq-right {
            .total {
                font-size: 16px;
                color: var(--mrq-green);
                font-weight: bold;
                margin: 0;
                text-align: left;
            }
        }
    }
}

.RQTrackingMap {
    height: 240px;
    width: 100%;
}

.RQUnderReview {
    width: 100%;
    color: var(--mrq-red);
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}

.RooQuestDeliverySection {

    .progress-wrapper {
        width: 95%;
        color: #fff;
        margin: 15px 2.5%;
        text-align: center;
        background-color: #f7f7f7;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }
    .progress-bar {
        padding: 4px;
        min-width: 10%;
        overflow: hidden;
        white-space: nowrap;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        background-color: #f63a0f;
        transition: 2s linear;
        transition-property: width, background-color;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);    
    }
    

    .additionalBtns {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 18px;

        .additionalBtn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 44px;
            border-radius: 8px;
            background: var(--mrq-button-main-background);
            margin: 0px 8px;
            font-size: 14px;
            color: var(--kargoroo-text);
            font-weight: bold;
            cursor: pointer;
            margin-bottom: 6px;

            &:active {
                background: var(--mrq-button-main-background-active);
            }
        }
    }

    .deliveryNotes {
        padding: 8px 18px 6px 18px;

        .title {
            color: var(--mrq-add-items-secondary-text);
            font-size: 16px;
            font-weight: bold;      
            margin-bottom: 2px;    
        }

        .deliveryNotesText {
            padding: 6px 12px;
            max-height: 108px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                display: block;
                width: 14px;
            }
        
            /* Track */
            &::-webkit-scrollbar-track {
                background: #e7e7e7;
                border-radius: 20px;
                border: 4px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
            }
        
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgb(126, 126, 126);
                border-radius: 20px;
                border: 4px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
            }
        }
    }
}


.MyRooQuestDelivery-footerBtns {
    display: flex;
    position: fixed;
    bottom: 12px;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px 12px 18px;

    .MyRooQuestDelivery-footerRight {
        display: flex;
        flex-direction: row;

        .btn:first-child {
            margin-right: 8px;
        }
    }

    .btn {
        p{
        color: var(--continue-button-color)
        }
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 20px 12px 20px;
        border-radius: 50px;
        background: var(--mrq-green-dark);
        font-weight: bold;
        font-size: 14px;
        color:white;
        margin: 0;
        text-align: center;
        // add box shadow
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        height: 48px;
        width: 160px;

        ion-icon {
            font-size: 22px;
            color: var(--ion-icon-dark-color);
        }

        &.chat {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            height: 48px;
            width: 48px;
            text-align: center;
        }

        &:active {
            background: var(--mrq-green);
        }
    }
}

.confirmCancelRooQuestAlert {
    --background: var(--confirm-cancel-rooquest);
    .alert-message{
        color :  var(--confirm-cancel-rooquest-text-color,--confirm-cancel-rooquest-buttons);
        color:var(--kargoroo-text);
    }

    .alert-title{
        color :  var(--confirm-cancel-rooquest-text-color,--confirm-cancel-rooquest-buttons);
        color:var(--kargoroo-text);
    }
   
    button{
        color: var(--confirm-cancel-rooquest-buttons) !important;
    }
}

// .RQTrackingMap{
//     .mapContainer {
//         // border: 1px solid blue;
        
//         height: 80vh !important;
//     }
    
//     .map {
//         // border: 1px solid red;
//     }

// }
.MyRooQuestDeliveryCompleted{
    overflow-y: hidden !important;
}