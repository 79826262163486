.MyRooQuestsPopover {


//   .deliveryMessage {
//     display: flex;
//     width: 100%;
//     align-items: center;
//     justify-content: center;
//     padding: 18px 18px 12px 18px;

//     p {
//         font-size: 15px;
//         color: black;
//         font-weight: bold;
//         margin: 0;
//         text-align: center;
//     }
// }

.filter{
  width: 95%;
  margin-left: 3%;
}

  .segmentContainer {
    margin: 8px 28px 4px 28px;
    ion-segment {
      background-color: var(--kargoroo-segment-bg-color);
      height: 36px;
      --color: var(--kargoroo-segment-text-color);
      font-size: 14px;
      font-family: 'Montserrat-Regular';
      font-weight: bold;
      --color-checked: var(--kargoroo-segment-active-text-color);

      ion-segment-button {
        ion-label {
          color: var(--kargoroo-segment-text-color);
        }        
      }

      ion-segment-button.segment-button-checked {
        ion-label {
          color: var(--kargoroo-segment-active-text-color);
        }        
      }

    }
    
  }

  .noRQs {
    margin-top: 20px;
    height: 160px;
  }

  .bodyContent {
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 120px;
    scrollbar-width: none; /* Firefox */
  }

  .rqCards {
    padding-top: 4px;
    cursor: pointer;

    .rqCardContent {
      .additionalInfoContainer {
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat-Regular';
        font-weight: bold;
        font-size: 13px;
        width: 100%;
        padding: 0px 4px 0px 12px;

        .additionalInfo {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 3px 0 3px 0;

          ion-icon {
            font-size: 15px;
            padding-right: 3px;
            padding-bottom: 1.5px;
            color:var(--kargoroo-icon-text-color);
          }

          span {
            color: var(--kargoroo-card-posted-color);
          }

          p {
            color: var(--kargoroo-card-posted-color);
            margin: 0;
            padding: 0;
          }
        }
        .rqtextcenter{
          justify-content: center;
          font-size: 16px;
        }
        .rqtextpadding{
          padding-top: 0px;
        }
      }
    }
  }
}
