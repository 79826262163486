.ReviewFormPage {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0 36px 0 36px;
    font-family: 'Poppins-SemiBold';

    overflow-y: auto;

    ion-grid {
        border-bottom: 2px solid #6b6b6b;
        font-family: "Poppins"
        
    }
    ion-row{
        margin: 0 8px;
        padding: 8px;
        border-bottom: 1px solid #e6e6e6;
        color: var(--kargoroo-text);
        font-size: 12.5px;
    }
    
    .iconContainer {
        display: flex;
        justify-content: flex-end;
        ion-icon{
            color: var(--mrq-green-dark) !important;
        }
        // align-items: center;
        // padding-left: 4rem;
    
    }

    .savings{
        .sectionTitle {
            color: var(--mrq-add-items-secondary-text);
            font-size: 16px;
            font-weight: bold;      
            margin-bottom: 2px;     
        }
    }

    &::-webkit-scrollbar {
        display: block;
        width: 14px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #e7e7e7;
        border-radius: 20px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(126, 126, 126);
        border-radius: 20px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }


    .reviewContent {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        

        .section {
            .sectionTitle {
                color: var(--mrq-add-items-secondary-text);
                font-size: 16px;
                font-weight: bold;      
                margin-bottom: 2px;                

            }
            .roovalidsectionTitle {
                color: var(--mrq-add-items-secondary-text);
                font-size: 14px;
                font-weight: bold;      
                margin-bottom: 2px;      
                text-align: center;     

            }
        }

        .cost {
            .cost-title-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 12px;

                section {
                    color: var(--mrq-add-items-accent);
                }
                
                ion-icon{
                    color: var(--mrq-green-dark) !important;
                }
            }        
        }

        .route {            
            margin-top: 12px;

            .routeContainer {
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: var(--ion-item-background, var(--ion-background-color, #fff));       
                border-radius: 6px;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;   
                padding: 12px 18px 12px 12px;  
                  
                .footer {
                    display: flex;
                    // position: absolute;
                    bottom: 12px;
                    height: 50px;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 18px;
        
                    .makeOfferBtn {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--mrq-green);
                        color: white;
                        border-radius: 50px;
                        font-weight: bold;
                        width: 70%;
                        max-width: 250px;
                        height: 40px;
        
        
                        &:active {
                            background-color: var(--mrq-green-dark);
                        }
        
                        ion-spinner {
                            color: black;
                            height: 50px;
                        }
        
                        &.loading {
                            background-color: #cfcfcf;
                        }
                    }
                }
                .route-item {
                    display: flex;
                    flex-direction: row;
                        
                    .iconContainer {
                        position: relative;
                        margin-right: 8px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        ion-icon {
                            display: flex;
                            align-items: center;
                            width: 24px;
                            font-size: 18px;
                            color: var(--mrq-green-dark);
                            flex-shrink: 0;
                        }

                        .dottedLine {
                            position: relative;
                            top: 4px;
                            height: 100%;
                            border-left: 2px dashed #d6d6d6;                            
                        }
                    }                    

                    p {
                        margin: 0;
                        font-size: 14px;
                        color: var(--mrq-add-items-secondary-text);

                        span {
                            color: var(--kargoroo-add-item-modal-text);
                        }
                    }

                    &:first-child {
                        margin-bottom: 8px;
                    }
                }

                .co2Saved {
                    p {
                        margin: 0;
                        // margin-top: 12px;
                        font-size: 13px;
                        text-align: center;
                        color: var(--mrq-add-items-secondary-text);
                        font-weight: bold;

                        &:first-child {
                            margin-top: 18px;
                            color: var(--kargoroo-text);
                        }
                    }
                }
            }

        }

        .additionalNotesHasNote {
            margin-top: 12px;
            margin-bottom: 18px;

            .additionalNotesContainer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                background-color:var(--review-additional-notes-container-background);       
                border-radius: 6px;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;   
                padding: 12px 18px 12px 12px;    
    
                p {
                    margin: 0;
                    white-space: pre-wrap;
                    font-size: 14px;
                    color: var(--mrq-add-items-secondary-text);
                }

                ion-icon {
                    margin-left: 12px;
                    flex-shrink: 0;
                    font-size: 18px;
                    color: grey;
                }
            }
        }

        

        .additional {
            margin: 16px 0 12px 0;

            .additionalNotesBtn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                
                p {
                    background-color: var(--kargoroo-review-btn-bg) ;
                    border-radius: 50px;
                    padding: 8px 24px;
                    font-size: 14px;
                    color: var(--mrq-add-items-secondary-text);

                    &:active {
                        background-color: #cfcfcf;
                    }
                }

            }
        }
        .review_savings{
            .sectionTitleRow {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 12px;
                color:rgb(16, 190, 95);
                font-weight: bold
            }
        }
    }
}

.RQCosts {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--rqcost-background);       
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

    ion-accordion {
        border-radius: 6px;
        

        ion-icon {
            font-size: 18px;
            color: grey;
        }

        ion-item {
            --border-color: #6b6b6b;
        }

        .cost-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 4px;
            border-bottom: 2px solid #6b6b6b;


            .maininfo {
                font-size: 13px;
                color: var(--mrq-add-items-accent);
                font-weight: bold;
                margin-bottom: 12px;
                margin: 0;
                text-align: center;
            }

            .subinfo {
                font-size: 12px;
                color: var(--kargoroo-text);
                // color: black;
                font-weight: bold;
                margin: 0;
                text-align: center;

            }

        }
    }

    .costAccordionTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .numItems {
            color: var(--mrq-add-items-secondary-text);
            font-size: 14px;
        }

        .itemCost {
            color: var(--mrq-add-items-secondary-text);
            font-size: 14px;
            font-weight: bold;

            span {
                display: inline-block;
                width: 80px;
                height: 30px;
                text-align: center;
                padding-top: 3px;
                color: white;
                font-weight: bold;
                font-family: "Poppins-SemiBold";
                padding-left: 5px;
                padding-right: 5px;
                border-radius: 30px;
                background-color: var(--mrq-green);
            }
        }
    }

    .reviewform-items {
        border-bottom: 2px solid #6b6b6b;

        .reviewform-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 0 8px;
            padding: 8px;
            border-bottom: 1px solid #e6e6e6;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                border-bottom: none;
            }

            color: var(--kargoroo-text);
            font-size: 12.5px;

            .itemCost {
                margin-left: 12px;
            }
            ion-icon {
                color: var(--mrq-green-dark);
            }
        }
    }
}  
.Lengthcenter{
    .LengthPicker {
        justify-content: center;
    }
}

.costAccordionTitle{
    .round-modify-edit-btn{
        display: inline-block;
        padding: 8px;
        height: 35px;
        width: 35px;
        border-radius: 50px;
        background: var(--mrq-green-dark);
    }
    ion-icon {
        font-size: 18px;
        font-weight: bold;
        position: relative;
        left: 1px;
        color: white !important;
    }
}

@media screen and (max-width: 385px) {
    .ReviewFormPage {
        padding: 0 5px 0 36px;
    }
   
  }

  @media screen and (max-width: 280px) {
    .ReviewFormPage {
        padding: 0 0 0 0;
    }
  }