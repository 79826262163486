/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@import "./colors.scss"; 
@import "./red.scss";

:root {
  --ion-color-kargoroo_green: #00bd00;
  --ion-color-kargoroo_green-rgb: 0, 189, 0;
  --ion-color-kargoroo_green-contrast: #ffffff;
  --ion-color-kargoroo_green-contrast-rgb: 255, 255, 255;
  --ion-color-kargoroo_green-shade: #00a600;
  --ion-color-kargoroo_green-tint: #1ac41a;

  --kargoroo-background-color : #00bd00;
  --kargoroo-dark-background-color: hsl(113, 100%, 13%);
  --kargoroo-single-quest-ion-item-bg: hsl(113, 100%, 13%);
  --kargoroo-single-item-quest-info-text-color:grey;
  --kargoroo-rooquest-info-text-color:grey;
  --kargoroo-segment-text-color:hsl(113, 100%, 13%);
  --kargoroo-segment-bg-color:#bfecb1;
  --kargoroo-segment-active-text-color:hsl(113, 100%, 13%);
  --kargoroo-icon-text-color:white;
  --kargoroo-card-bg-color:white;
  --kargoroo-address-card-bg:rgb(245, 238, 238);
  --kargoroo-card-color:white;
  --kargoroo-card-text-color:black;
  --kargoroo-card-price-text-color:rgb(110, 110, 110);
  --kargoroo-card-status-text-color:rgb(110, 110, 110);
  --kargoroo-card-posted-color:rgb(110, 110, 110);
  --kargoroo-message-card-background:white;
  --kargoroo-message-card-text-color:rgb(73, 73, 73);
  --kargoroo-single-item-quest-bg:white;
  --kargoroo-card-inner-container-bg:white;
  --kargoroo-card-inner-container-text-color:black;
  --kargoroo-single-item-quest-text-color:black;
  --kargoroo-single-item-quest-modify-bg:lightgrey;
  --kargoroo-my-req-del-bg:white;
  --kargoroo-my-req-del-text-header:#666;
  --kargoroo-my-req-del-text:black;
  --kargoroo-action-content-bg:white;
  --kargoroo-action-content-text:black;

  --kargoroo-modal-text:black;
  --kargoroo-text:black;
  --kargoroo-review-btn-bg:#dbdbdb;
  --kargoroo-ion-bottom-border:black;
  --kargoroo-message-chat-bg:white;
  --kargoroo-ion-icon-text:#666;
  --kargoroo-active-ion-icon:green;
  --kargoroo-ion-bottom-broder-color:black;

  --kargoroo-open-tutorial-bg:rgb(244, 244, 244);
  --kargoroo-open-tutorial-text-color:black;
  --kargoroo-open-tutorial-bullet-bg:rgb(61, 61, 61);

  --kargoroo-address-modal-bg:#084400;
  --kargoroo-address-header-text:white;


  --kargoroo-rooquest-text:black;

  --kargoroo-add-item-modal-text:rgb(107, 107, 107);
  --kargoroo-payment-page-text:#383838;
  --navbar-color: black;
  --modify-button :  var(--mrq-green);
  /*Mohamed*/
  --input-container-dark-background : white;
  --input-color:black;
  --input-background-color:white;
  --input-container-border-color: rgb(214, 214, 214);
  --ion-item-background:white;
  --continue-button-color: white;
  
  --kargoroo-active-ion-label:green;
  --add-item-btn: white;
  --edit-rooquest-color: rgb(75, 75, 75);
  --radio-button-color: var(--ion-color-primary);
  --notif-viewed-color: rgb(230, 247, 225);
  --mrq-question-dark: var( --mrq-grey-dark);
  --make-offer-description : var(--mrq-grey);
  --make-offer-title:rgb(37, 37, 37);
  --rooquest-check-list-section-title:black;
  --confirm-cancel-rooquest:white;
  --confirm-cancel-rooquest-buttons:var(--ion-color-primary);
  --confirm-cancel-rooquest-text-color:black;
  --confirm-on-the-way-color:linear-gradient(rgb(238, 238, 238), rgb(224, 224, 224));
  --amount-paid-ion-input: black;
  --confirm-on-the-way-modal:black;
  --confirm-check-box-ion-label: black;
  --action-buttons-ion-button:white;
  
  --pick-item-modal-background:white;
  --pick-item-modal-title-color:rgb(37, 37, 37);
  --pick-item-modal-ion-item-background:#e7e7e7;
  --pick-item-modal-item-title-color: black;
  --pick-item-modal-question-color:var(--mrq-grey-dark);
  --pick-item-modal-add-comment-background: white;
  --pick-item-modal-add-comment-color : black;
  --pick-item-modal-problem-with-item-ion-label-color:black;
  --pick-item-modal-shelf-price-type-color:black;
  --pick-item-modal-ion-radio-background:rgb(66, 68, 72);
  --review-additional-notes-container-background:white;
  --rqcost-background:white;
  --length-picker-input-color : white;
  --length-type-color : black;
  --addItemBtn-button-test-color:white;
  --suggestions-color:black;
  --suggestion-active-color:#eeeeee;
  --collecting-items-color:#4b4b4b;
  --progress-bar-text:black;
  --infoRQ-sectionContent-color : black;
  /*Fin*/
  --kargoroo-notification-icon : rgb(0, 75, 0);
}



.ion-color-kargoroo_green {
  --ion-color-base: var(--ion-color-kargoroo_green);
  --ion-color-base-rgb: var(--ion-color-kargoroo_green-rgb);
  --ion-color-contrast: var(--ion-color-kargoroo_green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-kargoroo_green-contrast-rgb);
  --ion-color-shade: var(--ion-color-kargoroo_green-shade);
  --ion-color-tint: var(--ion-color-kargoroo_green-tint);
}


/** Ionic CSS Variables **/
:root {
  /** primary **/
  /* --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
   */

  --ion-color-primary: #00bd00;
  --ion-color-primary-rgb: 0, 189, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00a600;
  --ion-color-primary-tint: #1ac41a;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --change-theme-lang-button: #737373;
}

/* @media (prefers-color-scheme: dark) { */
/*
   * Dark Colors
   * -------------------------------------------
   */

body.dark {
  /* --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff; */

  /*Mohamed*/
  --mrq-background:#31564e;
  --mrq-main-background: #10332d;
  --mrq-button-main-background:#3e5855;
  --input-container-dark-background : #264d48;
  --mrq-info-text-h1: #dad7bf;
  --mrq-add-items-secondary-text:#dad7bf;
  --input-color: #ede6cc;
  --input-background-color:#264d48;
  --input-container-border-color:#264d48;
  --ion-item-background:#31564e;
  --kargoroo-card-color:#10332d;
  
  --kargoroo-text:#dad7bf;
  --continue-button-color:#dad7bf;
  --add-items-click-color:#dad7bf;
  --ion-color-light: #10332d;
  --kargoroo-card-bg-color:#264d48;
  --kargoroo-background-color : #10332d;
  --ion-color-primary: #264d48;
  --ion-color-base:#10332d; 
  --kargoroo-active-ion-icon:#167a69;
  --kargoroo-active-ion-label:rgb(223, 217, 217);
  --navbar-color: #9f9f9f;
  --kargoroo-message-card-background:var(--kargoroo-card-color);
  --ion-color-kargoroo_green:#07332d;
  --clear-button-color:#dad7bf; 
  --notif-content-color:#dad7bf;
  --kargoroo-card-inner-container-bg:#264d48;
  --promo-page-color: #174b44;
  --add-item-btn:#dad7bf;
  --report-bug-background:#264d48;
  --dark-mode-row-of-images:#386f68;
  --setting-background-color: #264d48;
  --edit-rooquest-color : rgb(217, 200, 200);
  --kargoroo-review-btn-bg:#264d48;
  --radio-button-color: white;
  
  --mrq-add-items-secondary-text-light:#dad7bf;
  --notif-viewed-color: #31564e;
  --title-color: #dad7bf;
  --make-offer-background : #10332d;
  --mrq-question-dark: rgb(228, 223, 223);
  --make-offer-description : rgb(228, 223, 223);
  --make-offer-title:rgb(228, 223, 223);
  --kargoroo-my-req-del-bg:#10332d; /*messages background*/
  --kargoroo-message-chat-bg:#10332d;
  --rooquest-check-list-section-title:#dad7bf;
  
  --kargoroo-rooquest-text:#dad7bf;
  --ion-icon-dark-color: #dad7bf;
  --confirm-cancel-rooquest: #10332d;
  --confirm-cancel-rooquest-buttons:#dad7bf;
  --confirm-cancel-rooquest-text-color:#f0f0ed;
  --confirm-on-the-way-color:#31564e;
  --amount-paid-ion-input: #cdcdcd;
  --confirm-on-the-way-modal:#cdcdcd;
  --confirm-check-box-ion-label: #cdcdcd;
  --action-buttons-ion-button:#cdcdcd;
  --pick-item-modal-background:#10332d;
  --pick-item-modal-title-color:#cdcdcd;
  --pick-item-modal-ion-item-background: #31564e;
  --pick-item-modal-item-title-color:#dad7bf;
  --pick-item-modal-question-color:#cdcdcd;
  --pick-item-modal-add-comment-background: #31564e;
  --pick-item-modal-add-comment-color: #cdcdcd;
  --pick-item-modal-problem-with-item-ion-label-color:#cdcdcd;
  --pick-item-modal-ion-radio-background:white;
  --pick-item-modal-shelf-price-type-color:#cdcdcd;
  --review-additional-notes-container-background: var(--ion-item-background,var(--ion-background-color,#fff));
  
  --kargoroo-add-item-modal-text:#dad7bf;
  --rqcost-background: none;
  --length-picker-input-color : #31564e;
  --length-type-color : #dad7bf;
  --addItemBtn-button-test-color:#dad7bf;
  --suggestions-color: #e7e7e7;
  --suggestion-active-color:var(--ion-item-background, var(--ion-background-color, #fff));
  --collecting-items-color:#dad7bf;
  --progress-bar-text: #dad7bf;
  --infoRQ-sectionContent-color : #dad7bf;
  --remove-account-button: white;
  
  --kargoroo-modal-text:#dad7bf;
  --modify-button : var(--kargoroo-modal-text);
  /*Fin*/
  
  --kargoroo-dark-background-color: hsl(113, 100%, 13%);
  --kargoroo-single-quest-ion-item-bg: #00bd00;
  --kargoroo-single-item-quest-info-text-color: #b5b5b5;
  --kargoroo-rooquest-info-text-color:#b5b5b5;
  --kargoroo-segment-bg-color:rgb(158, 158, 158);
  --kargoroo-segment-text-color:black;
  --kargoroo-segment-active-text-color:white;
  --kargoroo-icon-text-color:white;
  --kargoroo-address-card-bg:#202020;
  --kargoroo-card-text-color:white;
  --kargoroo-card-price-text-color:lightgrey;
  --kargoroo-card-status-text-color:lightgrey;
  --kargoroo-card-posted-color:lightgrey;
  
  
  --kargoroo-message-card-text-color:white;
  --kargoroo-single-item-quest-bg:#424242;
 
  --kargoroo-card-inner-container-text-color:white;
  --kargoroo-single-item-quest-text-color:white;
  --kargoroo-single-item-quest-modify-bg:#2f2f2f;

  --kargoroo-my-req-del-text-header:white;
  --kargoroo-my-req-del-text:white;
  --kargoroo-action-content-bg:#424242;
  --kargoroo-action-content-text:white;
  /* --kargoroo-text:white; */
  /* --kargoroo-review-btn-bg:#424242; */


  --kargoroo-ion-bottom-border:white;

  --kargoroo-ion-icon-text:white;


  --kargoroo-ion-bottom-broder-color:white;

  --kargoroo-open-tutorial-bg:#424242;
  --kargoroo-open-tutorial-text-color:white;
  --kargoroo-open-tutorial-bullet-bg:white;

  --kargoroo-address-modal-bg:#cdcbcb;
  --kargoroo-address-header-text:black;
  
  /* --mrq-background:#202020; */
  /* --mrq-main-background: #2f2f2f; */
  /* --kargoroo-rooquest-text:white; */
  /* --mrq-info-text-h1: white; */
  /* --mrq-add-items-secondary-text:lightgrey; */
  /* --kargoroo-add-item-modal-text:white; */
  --kargoroo-payment-page-text:white;
  /* --mrq-add-items-secondary-text-light:white; */




  
  /* --mrq-background:#202020; */
  /* --mrq-main-background: #2f2f2f; */
  --kargoroo-payment-page-text:white;
  --kargoroo-notification-icon : #167a69;




 
  --ion-color-primary-rgb: 0, 189, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00a600;
  --ion-color-primary-tint: #1ac41a;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
  --change-theme-lang-button: #f3f3f3;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */
.ios body.dark {
  --ion-background-color: #424242;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;
  --ion-tab-bar-background: #121212;

}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
  /*Mohamed*/
  --ion-background-color: #264d48;
  /*Fin*/
  /* --ion-background-color: #424242; */
  --ion-background-color-rgb: 18, 18, 18;
  --ion-text-color: #ffffff;

  
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  /* --ion-item-background: rgb(49, 49, 49); */

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;
}
