.FULLPAGEMODALCARD {
  text-align: center;
  padding: 8px 12px 34px 12px;
  height: 100%;
  width: 100%;

  .cardContainer {
    color: white;
    background-color: var(--kargoroo-background-color,#00bd00);
    border-radius: 25px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    font-family: 'Montserrat-Regular';
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .cardTitle {
      position: relative;
      font-weight: bold;
    }

    .cardInnerContainer {
      color: var(--kargoroo-card-inner-container-text-color);
      background-color: var(--kargoroo-card-inner-container-bg);
      border-radius: 20px;
      margin: 4px;
      padding: 1px;
      position: relative;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }
}
