.popover {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 8;
  bottom: 0px;

  .popoverContainer {
    position: absolute;
    right: 12px;
    left: 12px;
    height: calc(100% - 144px);
    bottom: 50px;
    border-radius: 25px 25px 0 0;
    // background-color: #00bd00;
    background-color: var(--kargoroo-background-color,#00bd00);;
    color: white;
    font-family: 'Montserrat-Regular';
    text-align: center;

    .cardTitle {
      position: relative;
      font-weight: bold;
      font-size: 20px;
      margin: 10px;
      padding: 0;
    }

    .innerContainer {
      height: 100%;
      color: var(--kargoroo-card-text-color);
      background-color: var(--kargoroo-card-bg-color);
      // background-color: rgb(255, 255, 255);
      border-radius: 20px 20px 0 0;
      margin: 4px;
      padding: 1px;
      position: relative;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .popoverContainer{
    width: 550px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
      .popoverContainer{
        width: 550px;
        margin: 0 auto;
  }
}

@media screen and (min-width: 1601px) {
  .popoverContainer{
    width: 550px;
    margin: 0 auto;
  }
}
