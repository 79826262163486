.deliverySuccess{
    margin-top: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // border: 1px solid red;
    height: 75vh;

    &::-webkit-scrollbar {
        display: block;
        width: 11px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #e7e7e7;
        border-radius: 20px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(126, 126, 126);
        border-radius: 20px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
    .section{
        // background-color: #35558a;
        height: 100vh;
    }

    .container{
        padding: 3rem 0 ;
        height: 100%;
    }

    .modal-content{
        border:1px solid black;
        width: 37vw;
        // height: 75vh !important;
        // margin-top: 1rem;
        // overflow: hidden auto !important;
    }

    .row{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        // d-flex justify-content-center align-items-center h-100 text-center
    }

    .modal-header {
        border-bottom: 0;
        text-align: right;
    }

    .modal-body{
        color: black;
        padding: 1.5rem;
        text-align: left;
        // text-start text-black p-4
        .modal-title{
            // text-uppercase mb-5
            margin-bottom: 1.5rem;
            text-transform: uppercase;
        }
        .thanks-title{
            color: #35558a;
            margin-bottom:1.5rem ;
        }

        .payment-summary{
            margin-bottom: 0;
            color: #35558a;
        }

        .delimiter{
            margin-top:0.5rem ;
            margin-bottom: 1.5rem;
            // mt-2 mb-4
            height: 0; 
            background-color: transparent; 
            opacity: .75; 
            border-top: 2px dashed #9e9e9e;
        }

        .items{
            display: flex;
            justify-content: space-between;
            margin-top: 0.75rem;
            // d-flex justify-content-between
            .item{
                font-weight: bold;
                margin-bottom: 0;
                // fw-bold mb-0
            }

            .item-price{
                margin-bottom: 0;
                color: gray;
                // text-muted mb-0
            }
        }

        .shipping {
            // d-flex justify-content-between small
            display: flex;
            justify-content: space-between;

            p{
                margin-bottom: 0;
            }
        }

        .total{
            // d-flex justify-content-between
            display: flex;
            justify-content: space-between;
            margin-top : 0.75rem;
            p{
                font-weight: bold;
            }

            .total-price{
                color: #35558a;
            }
        }
    }

    .modal-footer{
        // d-flex justify-content-center border-top-0 py-4
        display: flex;
        justify-content: center;
        border-top: 0;
        padding: 0.5rem;

        .controlBtn {
            width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            color: white;
            font-size: 14px;
            font-weight: bold;
            border-radius: 50px;
            margin: 4px;   
            padding: 16px;
    
    
            &.continueBtn {
                color: var(--continue-button-color);
                background: var(--mrq-button-main-background);
                width: 180px;
                ion-icon {
                    position: relative;
                    left: 4px;
                }
    
                &:active {
                    background: var(--mrq-button-main-background-active)
                }
    
                &.disabled {
                    background: var(--mrq-button-secondary-background-active);
                    color: var(--mrq-grey);
                    opacity: 0.7;                            
                }
            }
        }
    }

    @media screen and (max-width: 788px) {

        
        .modal-content{
            border:1px solid black;
            width: 100%;

            
        }
    
    }

    @media screen and (max-height: 732px) {

        // .modal-content{
        //     // overflow-x: scroll;
        //     // background-color: #35558a;
        //     // height: 70vh;
        // }
    
    }

    @media screen and (min-width: 788px) {

        
        .modal-content{
            border:1px solid black;
            width: 530px;
            
        }
    
    }

    
}