.RouteInfoVertical {
  width: 100%;

  .routeObjContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Montserrat-Regular';
    color: rgb(48, 48, 48);
    margin: 6px 2px 8px 6px;

    .verticalLine {
      padding: 0 0 0 16px;
      margin: 0;
      height: 24px;
      width: 0px;
      border-right: 2.2px dashed #00bd00;
    }
    .totalDistance{
      font-size: 14px !important;
      top: 0px !important;
      color: #00bd00;
      font-weight: bold;
      font-family: "Poppins";
      margin-left: 2px;
    }
    .car {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 3px;

      img {
        height: 20px;
      }

      .youLabel {
        font-size: 12px;
        font-weight: bold;
        color: black;
        margin: 0;
        position: relative;
        left: 2px;
      }
    }

    .carRouteComponent {
      display: flex;
      flex-direction: row;
      // min-width: 60px;

      p {
        white-space: nowrap;
        position: relative;
        top: 4px;
        margin: 0;
        padding: 0 0 0 4px;
        font-size: 11px;
      }
    }

    .box {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        height: 28px;
        width: 28px;
        margin: 3px;
      }

      .pickupLocation {
        font-size: 12px;
        font-weight: bold;
        color: var(--kargoroo-my-req-del-text);
        margin: 0;
        position: relative;
        left: 4px;
        text-align: left;
        padding-right: 21px;
      }
    }

    .packageRouteComponent {
      display: flex;
      flex-direction: row;
      // min-width: 60px;

      p {
        white-space: nowrap;
        position: relative;
        top: 4px;
        margin: 0;
        padding: 0 0 0 4px;
        font-size: 11px;
      }
    }

    .deliverByComponent {
      display: flex;
      flex-direction: row;
      font-size: 11px;
      padding-top: 3px;

      .deliverBy {
        margin: 0;
        padding: 0;
        font-weight: bold;
        color: var(--kargoroo-my-req-del-text);
        font-size: 12px;

        .title {
          font-size: 11px;
          padding-right: 2px;
          color: var(--kargoroo-rooquest-info-text-color);
        }
      }
    }

    .pin {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        height: 28px;
        margin: 3px;
        position: relative;
        left: 5px;
      }

      .dropoffLocation {
        font-size: 12px;
        font-weight: bold;
        color: var(--kargoroo-my-req-del-text);
        margin: 0;
        position: relative;
        left: 13px;
        text-align: left;
        padding-right: 21px;
      }
    }
  }
}
