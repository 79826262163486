.home, .register{
  ion-content {
    --background: rgba(23, 23, 23, 1);
    --overflow : scroll;
  }
  
  ion-page {
    --background: rgba(23, 23, 23, 1);
  }
  .rem-mar-left{
    margin-left: 5px;
  }
  
  .homepage {
    ion-content {
      --overflow: scroll;
    }
  }
  .versionContainer {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .home-versionNumber {
    margin: 40px 12px 12px 12px;
    background-color: rgba(255, 255, 255, 0.87);
    height: 20px;
    width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    border-radius: 50px;
    justify-content: center;
    text-align: center;
  }
  
  .home-container {
    background-color: rgba(23, 23, 23, 1);
    height: 100%;
    width: 100%;
    display: block;
    text-align: center;
    font-family: 'Montserrat-Regular';
  
    .top-image {
      width: 100%;
      height: 20vh;
      position: relative;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: white !important;
      -webkit-box-shadow: 0 0 0 30px #171717 inset !important;
      box-shadow: 0 0 0 30px #171717 inset !important;
    }
    .form-inputs {
      position: relative;
  
      .form-input {
        color: white;
        font-size: 20px;
      }
    }
    .eye-password{
      ion-icon {
        color: #8b8b8b;
      }
    }
    .form-email {
      margin-top: 20px;
    }
  
    .form-password {
      margin-top: 10px;
  
      ion-button {
        font-family: 'Montserrat-Regular';
        font-size: 13px;
        font-weight: bold;
        --background: none;
        --color: #00bd00;
        text-transform: none;
      }
    }
  
    .signin-btn {
      margin-top: 50px;
      margin-left: 10%;
      margin-right: 10%;
      height: 50px;
      font-size: 18px;
      text-transform: none;
      font-family: 'Montserrat-Regular';
    }
  
    .createaccount-btn {
      color: #00bd00;
      font-size: 16px;
      text-decoration: none;
      position: relative;
      top: 12px;
    }
  
    .createaccount-btn:hover {
      color: #7eeb7e;
    }
  
    .alternateLoginContainer {
      // background-color: rgba(23, 23, 23, 1);
      position: relative;
      color: white;
      top: 54px;
      font-size: 18px;
      padding-bottom: 52px;
  
      .row {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        position: relative;
        top: 10px;
      }
  
      .social_login {
        width: 75%;
        max-width: 360px;
  
        // width: 36%;
        margin-left: 4px;
        margin-right: 4px;
        font-size: 14px;
        font-family: 'Montserrat-Regular';
        --border-color: white;
        --background: white;
        height: 36px;
        text-transform: none;
  
        .googlelogo {
          width: 20px;
          position: relative;
          top: 2px;
        }
  
        .apple {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          align-content: center;
          position: relative;
          // top: 18px;
  
          p {
            position: relative;
            top: 1px;
            padding-left: 10px;
            color: black;
            font-size: 16px;
            font-weight: bold;
          }
  
          .applelogo {
            height: 20px;
          }
        }
  
        .google {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          align-content: center;
          position: relative;
  
          .google-text {
            position: relative;
            bottom: 3px;
            margin-left: 6px;
          }
          p {
            position: relative;
            top: 1px;
            padding-left: 10px;
            color: black;
            font-size: 16px;
            font-weight: bold;
          }
        }
  
        .apple {
          color: white;
  
          .apple-text {
            position: relative;
            bottom: 3px;
            margin-left: 6px;
          }
        }
      }
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1199px) {
      .home-container {
        .signin-btn{
          margin: 0 auto;
          margin-top: 30px;
          width: 250px !important;
        }
        form {
          width: 450px;
          margin: 0 auto;
        }
        .haveaccount-btn{
          padding-bottom: 10px;
        }
    }
  }
  
  @media screen and (min-width: 1200px) and (max-width: 1600px) {
    .home-container {
        .signin-btn{
          margin: 0 auto;
          margin-top: 30px;
          width: 250px !important;
        }
        form {
          width: 550px;
          margin: 0 auto;
        }
        .haveaccount-btn{
          padding-bottom: 10px;
        }
   }
  }
  
  @media screen and (min-width: 1601px) {
    .home-container {
      .signin-btn{
        margin: 0 auto;
        margin-top: 30px;
        width: 250px !important;
      }
      form {
        width: 550px;
        margin: 0 auto;
      }
      .haveaccount-btn{
        padding-bottom: 10px;
      }
  }
  }

}
