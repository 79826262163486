.FullQuest .questcontainer .fullInnerContainer{
    height: calc(100vh - 60px) !important;
    background-color: var(--setting-background-color,white);
    .unitmethod {
        text-transform:capitalize;
    }
    ion-item {
        --ion-item-border-color: var(--kargoroo-ion-bottom-broder-color);  
    }
    
   
}
ion-alert {
    .alert-radio-inner{
        background-color:var(--radio-button-color) !important;
    }
    .alert-radio-icon{
        border-color: var(--ion-color-step-550) !important;
    }
    span.alert-button-inner.sc-ion-alert-md{
        color:var(--radio-button-color) !important
    }
}

.my-custom-class{
    color: var(--confirm-cancel-rooquest-text-color, --confirm-cancel-rooquest-buttons);
    --background: var(--confirm-cancel-rooquest);

    button{
        color:var(--remove-account-button);
    }
}



@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .FullQuest .questcontainer .fullInnerContainer .questreqdisplay{
        width: 550px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .FullQuest .questcontainer .fullInnerContainer .questreqdisplay{
        width: 550px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1601px) {
    .FullQuest .questcontainer .fullInnerContainer .questreqdisplay{
        width: 550px;
        margin: 0 auto;
    }
}

.my-custom-interface .alert-radio-icon.sc-ion-alert-md{
    border-color:  var(--change-theme-lang-button) !important;
}