ion-picker {
  touch-action: none;
}

.postRQModalContent {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;

  h1 {
    margin: 10px;
    padding: 0px;
    color: #666;
  }

  ion-slides {
    height: 100%;
    width: 100%;
  }

  .cardBodyContent {
    height: inherit;
    overflow-y: scroll;

    ion-item {
      padding-top: 4px;
    }

    .inputField {
      padding-right: 12px;
      ion-label {
        font-family: 'Montserrat-Regular';
        font-size: 16px;
        // font-weight: bold;
        // color: rgb(240, 70, 70);
      }
    }

    .formErrorMsg {
      font-size: 10px;
      font-family: 'Montserrat-Regular';
      color: red;
      margin: 0px;
      padding: 0px;
    }
    .currentLoc {
      font-size: 15px;
      font-family: 'Montserrat-Regular';
      color: green;
    }

    .uploadedImage {
      margin: 8px;
      height: 136px;
      width: 136px;
      object-fit: cover;
      align-self: center;
      border: 2px solid #084400;
      border-radius: 25px;
    }

    .imageUploadBtn {
      align-self: center;
      font-size: 16px;
      font-family: 'Montserrat-Regular';
      --background: #268819;
      --background-activated: #29971b;
      --background-hover: #29971b;
      height: 28px;
      width: 170px;
      margin: 2px 0px 16px 0px;
    }

    .summaryContainer {
      align-self: center;
      padding-right: 16px;
    }

    .slide3 {
      &.paymentDetails {
        display: flex;
        flex-direction: row;
        padding-top: 6px;

        .paymentItemLabels {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          h5 {
            color: rgb(110, 110, 110);
          }
        }

        .paymentItemPrices {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .paymentItem {
          color: black;
          font-size: 16px;
          font-weight: bold;
          font-family: 'Montserrat-Regular';
          text-align: start;
          padding-left: 10px;
          padding-bottom: 8px;
          padding-top: 4px;
          margin: 0px;
        }
        .total {
          font-size: 18px;
        }
      }
    }
    .successContainer {
      color: black;
      font-family: 'Montserrat-Regular';
      margin: 0 4px 0 4px;
    }
    .errorContainer {
      color: black;
      font-family: 'Montserrat-Regular';
      margin: 0 4px 0 4px;
    }

    .confirmationSlide,
    .paymentSlide {
      .titleContainer {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;

        #title-rqImg {
          margin: 8px;
          height: 48px;
          width: 48px;
          border: 2px solid #084400;
          border-radius: 25px;
          object-fit: cover;
        }

        .rqTitle {
          color: black;
          position: relative;
          font-family: 'Montserrat-Regular';
          font-size: 20px;
          font-weight: bold;
        }
      }
      .rqinfo {
        color: black;
        font-size: 14px;
        font-weight: bold;
        font-family: 'Montserrat-Regular';
        text-align: start;
        padding-left: 24px;
        padding-bottom: 8px;
        margin: 0px;
        white-space: pre-wrap;

        span {
          color: rgb(110, 110, 110);
          padding-right: 8px;
        }
      }
      .cardElementContainer {
        padding: 20px 20px 18px 20px;
      }
    }
  }

  .cardFooter {
    .navButtons {
      position: relative;
      font-family: 'Montserrat-Regular';
      font-size: 24px;

      .backBtn {
        width: 25%;
        padding-right: 5px;
        font-family: 'Montserrat-Regular';
        font-size: 16px;
      }

      .nextBtn {
        width: 55%;
        padding-left: 5px;
        font-family: 'Montserrat-Regular';
        font-size: 18px;
      }

      .nextBtn.slide4 {
        font-size: 15px;
      }
    }
  }
}

// .pickLocationTypeSelector .alert-wrapper {
//   min-width: 85%;
// }

// .deliverToSelector .alert-wrapper {
//   min-width: 85%;
// }
