.tutorialModal {
  height: 100%;
  width: 100%;

  .tutorialModalContent {
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    // font-family: 'Montserrat-Regular';
    font-family: 'Raleway-Medium';

    // .swiper-container {
    //   background: rgb(244, 244, 244);
    // }
    .tutoriallefticon{
      position: absolute;
      top: 50%;
      left: 16px;
      font-size: 25px;
      z-index: 2;
      ion-icon {
        color: #00bd00;
      }
    }
    .tutorialrighticon{
      position: absolute;
      top: 50%;
      right: 16px;
      font-size: 25px;
      z-index: 2;
      ion-icon {
        color: #00bd00;
      }
    }
    ion-slides {
      height: 100%;
      width: 100%;
      background: rgb(244, 244, 244);
      --bullet-background: var(--kargoroo-open-tutorial-bullet-bg);
    }

    ion-slide {
      background: var(--kargoroo-open-tutorial-bg);
      color:var(--kargoroo-open-tutorial-text-color);

      .slide1 {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        margin: 12px;
        padding: 8px;

        .slideContent {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          align-content: center;

          .topContent {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            align-content: center;

            .confetti {
              position: fixed;
              top: 0;
              z-index: 1;
              width: 100%;
              height: 114px;
              object-fit: cover;
            }

            .logo {
              display: block;
              max-width: 95%;
              width: 360px;
              object-fit: contain;
              z-index: 5;
              padding-top: 128px;
            }
          }

          .bottomContent {
            height: 45vh;
            h1 {
              font-family: 'Raleway-SemiBold';
              font-size: 28px;
            }

            ion-input {
              z-index: 10;
            }
            ion-item {
              z-index: 5;
            }
          }
        }
      }

      .slide2 {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        margin: 12px;
        padding: 8px;

        .slideContent {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          align-content: center;

          .topContent {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            align-content: center;

            .topImage {
              max-width: 94%;
              max-height: 320px;
              // height: 200px;
              width: 360px;
            }
          }

          .bottomContent {
            height: 45vh;
            h1 {
              font-family: 'Raleway-SemiBold';
              font-size: 28px;
            }
            padding-bottom: 30px;

            ion-item {
              --border-color: grey;
              --border-style: solid;
              --border-width: 2px;
              --border-radius: 10px;
            }

            .failure {
              --border-color: rgb(230, 56, 56);
              --border-style: solid;
              --border-width: 2px;
              --border-radius: 10px;
            }

            .success {
              --border-color: #00bd00;
              --border-style: solid;
              --border-width: 2px;
              --border-radius: 10px;
            }
          }
        }
      }

      .slide3 {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        margin: 12px;
        padding: 8px;

        .slideContent {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          align-content: center;

          .topContent {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            align-content: center;

            h2 {
              font-size: 22px;
              padding: 0;
              margin: 0;
            }

            .confetti {
              position: fixed;
              top: 0;
              z-index: 1;
              width: 100%;
              height: 114px;
              object-fit: cover;
            }

            .topImage {
              display: block;
              max-width: 98%;
              width: 360px;
              object-fit: contain;
            }
          }

          .bottomContent {
            height: 45vh;
            h1 {
              font-family: 'Raleway-SemiBold';
              font-size: 28px;
            }
          }
        }
      }

      .slide4 {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        margin: 12px;
        padding: 8px;

        .slideContent {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          align-content: center;

          .topContent {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            align-content: center;

            h2 {
              font-size: 22px;
              padding: 0;
              margin: 0;
            }

            .confetti {
              position: fixed;
              top: 0;
              z-index: 1;
              width: 100%;
              height: 114px;
              object-fit: cover;
            }

            .topImage {
              display: block;
              max-width: 98%;
              width: 360px;
              object-fit: contain;
            }
          }

          .bottomContent {
            height: 45vh;
            h1 {
              font-family: 'Raleway-SemiBold';
              font-size: 28px;
            }
          }
        }
      }

      .slide5 {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        margin: 12px;
        padding: 8px;

        .slideContent {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          align-content: center;

          .topContent {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            align-content: center;

            .confetti {
              position: fixed;
              top: 0;
              z-index: 1;
              width: 100%;
              height: 114px;
              object-fit: cover;
            }

            .topImage {
              display: block;
              max-width: 220px;
              max-height: 220px;
              width: 360px;
              object-fit: contain;
              -webkit-transition: transform 0.7s;
              transition: transform 0.7s;
            }
            .rotate {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }

          .bottomContent {
            height: 45vh;
            h1 {
              font-family: 'Raleway-SemiBold';
              font-size: 28px;
            }

            ion-button {
              width: 180px;
              --border-width: 2px;
              --border-color: #044800;
              --border-style: solid;
              --border-radius: 25px;
            }
          }
        }
      }
    }

    // .modalWrapper {
    //   --background: purple;
    // }
  }
}
