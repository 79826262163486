.currentLocationModal {
  font-family: 'Montserrat-Regular';
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 100px 0;

  &::part(content) {
    --border-color: #044800;
    --border-style: solid;
    --border-width: 4px;
    --border-radius: 25px;
    // height: 350px;
    height: 80%;
    min-height: 400px;
    width: 95%;
    min-width: 300px;
  }

  .coordinatesContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #048800;
    height: 64px;

    h3 {
      font-size: 22px;
      color: white;
      font-weight: bold;
    }
  }

  &::part(backdrop) {
    --backdrop-opacity: 0.6;
  }

  .inputElements {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .actionButtons {
    position: absolute;
    bottom: 20px;
    width: 100%;
    z-index: 20;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 2px 8px 4px 8px;

      ion-button {
        --border-color: #044800;
        --border-style: solid;
        --border-width: 2px;
        --border-radius: 15px;
        flex-grow: 1;
      }
    }
  }
}