.new-background-color{
    --background: var(--kargoroo-dark-background-color);
    font-family: 'Montserrat-Regular';
    color: white;
}
.GreenHeader ion-toolbar, ion-header {
    --min-height: 68px;
    padding-top: 0px !important;
   }
.GreenHeader ion-back-button{
    --color: white !important;
  }
.GreenHeader ion-title{
    font-family: 'Montserrat-Regular';
    font-weight: 300;
    font-size: 20px;
  }