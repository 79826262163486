.messageCard {
  margin: 8px 8px 8px 8px;
  padding: 4px;

  background-color: var(--kargoroo-message-card-background);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 12px 0 rgba(0, 0, 0, 0.2);

  cursor: pointer;
  .cardContent {
    overflow: hidden;

    .topRow {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .imgTextContainerGroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        overflow: hidden;

        .rqImg {
          height: 54px;
          width: 53px;
          margin: 3px;
          margin-right: 8px;
          border: 1px solid #084400;
          border-radius: 50px;
        }

        .textComponent {
          font-family: 'Montserrat-Regular';
          flex-direction: column;
          margin: 0;
          padding: 0;
          text-align: start;
          overflow: hidden;

          h5 {
            padding: 0;
            margin: 0;
            font-size: 17px;
            font-weight: bold;
          }

          p {
            padding: 0;
            margin: 0;
            font-size: 14px;
            font-weight: bold;
            color: var(--kargoroo-message-card-text-color);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .lastMessage {
            font-weight: 100;
            color: var(--kargoroo-message-card-text-color);
          }
          .lastMessageTime {
            font-size: 11.5px;
            font-weight: 100;
            color: var(--kargoroo-message-card-text-color);
          }
        }
      }

      .unreadComponent {
        padding-right: 12px;
        ion-badge {
          background-color: #e94040;
        }
        // align-self: flex-end;
      }
    }
  }
}
