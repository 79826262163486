.navbar {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 10;
  height: 70px;
  padding-bottom: 14px;
  background-color:var(--ion-color-light);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

ion-tab-button{
  &.tab-selected{
    ion-icon{
      color: var(--kargoroo-active-ion-icon) !important;
    }
    ion-label{
      color: var(--kargoroo-active-ion-label) !important;
    }
  }

  ion-icon{
      color: var(--navbar-color);
    }
    ion-label{
      color: var(--navbar-color);
    }
}
// ion-tab-button{
//   // ion-icon{
//   //   color: var(--kargoroo-ion-icon-text);
//   // }
//   // ion-label{
//   //   color: var(--kargoroo-ion-icon-text);
//   // }
// }

@media (max-width:420px) {
  ion-tab-button{
    &.tab-selected{
      ion-label{
        font-size: 10px !important;
      }
    }
      ion-label{
        font-size: 10px !important;
      }
  }
}
