.AutoCompleteWithMap {
    .controlButtonDiv{
        background: none padding-box rgb(255, 255, 255);
        display: table-cell;
        border: 0px;
        margin: 2px;
        padding: 0px 23px;
        text-transform: none;
        appearance: none;
        position: relative;
        cursor: pointer;
        user-select: none;
        direction: ltr;
        overflow: hidden;
        text-align: center;
        height: 40px;
        vertical-align: middle;
        color: rgb(86, 86, 86);
        font-family: Roboto, Arial, sans-serif;
        font-size: 18px;
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        min-width: 64px;
        font-style: inherit;
        font-feature-settings: inherit;
        font-variant: inherit;
        line-height: 1;

        &:hover {
            background: none padding-box rgb(235, 235, 235);
        }
    }
    .google-autocomplete-container {
        width: 100%;
        padding: 0 4px;
        

        .inputContainer {
             .pickup, .dropoff{
                font-size: 14px;
                border: 1px var(--input-container-border-color) solid;
                border-radius: 80px;
                width: 100%;
                height: 42px;
                background-color: var(--input-container-dark-background);
                margin-top: 12px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
             }

            
            

            input {
                color: var(--input-color);
                overflow-x: scroll;
                padding: 8px 12px;
                height: 40px;
                background-color: var(--input-background-color);
                // background-color: red;
                text-decoration: none;
                border: none;
                flex: 1 1 auto;
                border-radius: 80px;          

                &:focus {
                    outline: none;
                }
            }

            span {
                padding: 6px;
                display: flex;
                font-size: 20px;
                color: var(--clear-button-color);

                &:active {
                    color: rgb(0, 199, 33);
                }
            }
        }        
    }

    .google-map-confirm-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 12px;
        // background-color: red;
        // height: 500px;
        border-radius: 8px;

        &.hasMap {
            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        }

        overflow: hidden;



        #google-map-pickup-confirm {
            width: 100%;
            border-radius: 8px;
            height: 100%;
            min-height: 150px;
        }

        // .address {
        //     margin: 8px;
        //     padding: 4px;
        //     background-color: white;
        //     position: absolute;
        //     top: 0;
        //     font-size: 11px;
        //     font-weight: bold;
        //     border-radius: 8px 8px 0px 0px;
        //     border: 1px green solid;
        // }

        .address {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: --var(mrq-main-background);       
            font-size: 12px;
            padding: 6px;

            .name {
                font-size: 13px;
                font-weight: bold;
            }
        }
    }

    
    .gm-ui{
        color: red !important;
    }
    .gm-style .gm-style-iw-c {
        display: flex;

        h3 p{
            text-align: center;
            margin: 0 0 10px;

        }
        h3{
            font-size: 18px;
            line-height: 22px;
            text-decoration: none !important;
            padding: 0;
            border: 0;
            font-family: "Muli", sans-serif;
        }
        p{
            font-size: 13px;
        }
        
    .info-window-content {
        align-items: center;
        justify-content: center;
        border:2px solid blue;
    }
        // button{
        //     background: none;
        //     display: block;
        //     border: 0px;
        //     margin: 0px;
        //     padding: 0px;
        //     text-transform: none;
        //     appearance: none;
        //     position: absolute;
        //     cursor: pointer;
        //     user-select: none;
        //     top: -6px;
        //     right: -6px;
        //     width: 30px;
        //     height: 30px;
        //     opacity: 0.6;
        // }
        
    }
    
}