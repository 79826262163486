.MODALCARD {
  .cardContainer {
    color: white;
    background-color: var(--kargoroo-background-color,#00bd00);;
    border-radius: 25px;
    width: 95%;
    max-width: 600px;
    display: inline-block;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 12px;
    font-family: 'Montserrat-Regular';

    .cardTitle {
      position: relative;
      font-weight: bold;
    }

    .cardBody {
      color: black;
      background-color: white;
      border-radius: 20px 20px 20px 20px;
      margin: 4px;
      padding: 1px;
      position: relative;
    }
  }
}
