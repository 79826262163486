.MessagesPopover {
  .messagesContainer {
    padding-top: 14px;
  }
    .bodyContent {
      overflow-y: scroll;
      height: 100%;
      padding-bottom: 120px;
      scrollbar-width: none; /* Firefox */
    }

    .noMessages {
      margin-top: 20px;
      height: 160px;
    }
  
}
