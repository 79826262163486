.MakeOfferModal {
    backdrop-filter: blur(3px);

    --min-width: 80%;
    --max-width: 800px;
    --min-height: 80%;
    --height: 92%;
    --width: 100%;
    
    &::part(background) {
        border-radius: 20px;
    }

    &::part(handle) {
        background: var(--mrq-green-light);
        width: 100px;
        height: 6px;
    }

    &::part(content) {
        border-radius: 0px;
        box-shadow: 0px 0px 20px 5px rgba(5, 5, 5, 0.32);
        background-color: white;
    }

    ion-content {
        --background: var(--make-offer-background,white);
    }

    .MakeOfferModalContent {

        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        font-family: 'Poppins';

        .header {
            height: 50px;
            margin-bottom: 16px;


            .closeBtn {
                position: absolute;
                top: 6px;
                right: 8px;
                font-size: 32px;
                color: rgb(150, 150, 150);
            }

            .title {
                margin-top: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
    
                p {            
                    margin: 18px 0 8px 0;    
                    font-size: 20px;
                    font-weight: bold;
                    color: var(--make-offer-title)
                }
            }
        }
    
        .main {
            display: flex;
            flex-direction: column;
            height: 100px;
            width: 100%;
            flex-grow: 1;

            overflow-y: auto;

            .description {
                text-align: center;
                padding: 12px 32px;
                color: var(--make-offer-description);
                font-size: 15px;
            }

            .inputsContainer {
                padding: 0px 32px 12px 32px;


                .inputSection {
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    .question {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-end;
                        color: var(--mrq-question-dark);
                        font-weight: bold;
                        font-size: 16px;

                        p {
                            margin: 0;
                        }

                        .subInfo {
                            font-size: 13px;
                            margin-left: 2px;
                        }
                    }

                    .deliveryFeeOffer {
                        margin: 12px;
                        text-decoration: none;
                        text-align: start;
                        font-weight: bold;
                        outline: none;
                        border: none;
                        color: var(--mrq-green);
                        font-size: 24px;
                        width: 128px;
                        border-bottom: 2px green solid;
                        border-color: var(--mrq-green);

                        margin-right: 12px;
                        background-color: var(--input-container-dark-background);
                    }

                }

            }
            

            
        }
    
        .footer {
            display: flex;
            // position: absolute;
            bottom: 12px;
            height: 50px;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-bottom: 18px;

            .makeOfferBtn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: var(--mrq-green);
                color: white;
                border-radius: 50px;
                font-weight: bold;
                width: 70%;
                max-width: 350px;
                height: 50px;


                &:active {
                    background-color: var(--mrq-green-dark);
                }

                ion-spinner {
                    color: black;
                    height: 50px;
                }

                &.loading {
                    background-color: #cfcfcf;
                }
            }
        }
    }

    .deliveryMessage {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 18px 18px 12px 18px;

        p {
            font-size: 15px;
            color: var(--kargoroo-rooquest-text);
            font-weight: bold;
            margin: 0;
            text-align: center;
        }
    }
}

.MakeOfferModalPopover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    --min-width: 80%;
    --max-width: 800px;
    --min-height: 80%;

    --height: 75%;
    --width: 100%;
    
    &::part(content) {
       width: 100%;
       --width: 100%;
    }

    --backdrop-opacity: 0.5;

    .MakeOfferModalDateInput {
        display: flex;
        flex-direction: column;
        margin: auto;
    }

}

.LengthPicker {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 12px;

    .lengthPicker {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .lengthType {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color:var(--length-type-color);

            
            .round-edit-btn{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                height: 48px;
                width: 48px;
                border-radius: 50px;
                background: var(--mrq-green-dark);
                box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
                margin-left: 8px;
            }

            .round{
                --padding-start: 10px;
                height: 30px;
                margin-left: 10px;
            }
            ion-icon {
                position: relative;
                left: 1px;
                bottom: 1px;
                font-size: 22px;
                font-weight: bold;
                // color: var(--mrq-green-dark);
                color: white;
            }
        }


        input {
            text-decoration: none;
            text-align: center;
            font-weight: bold;
            outline: none;
            border: none;
            color: var(--mrq-green);
            font-size: 24px;
            width: 65px;
            border-bottom: 2px green solid;
            border-color: var(--mrq-green);
            background-color: var(--length-picker-input-color);
            margin-right: 12px;
        }
    }
}

.chooseTypePopover {
    --offset-x: 10px;
    --offset-y: -10px;

    .choices {
        padding: 12px;

        p {
            margin: 0;
            padding: 14px;
            width: 100%;
            border-bottom: 1px solid rgb(206, 206, 206);
            font-family: 'Poppins';
            color: var(--kargoroo-modal-text);

            &:last-child {
                border-bottom: none;
            }

            &.selected {
                color: var(--mrq-green-dark);
                font-weight: bold;
            }
        }
    }

}