.singleitempayment ion-content {
    // --overflow: hidden !important;
    --background: var(--kargoroo-dark-background-color);
    
    .singlepaymentlabel{
        font-size: 12px;
        font-weight: bold;
        color: var(--kargoroo-single-item-quest-info-text-color);
        margin: 0px;
    }
    .singleitempaymentcard{
        padding: 15px;
    }
}