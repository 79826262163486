.notavailable {
    font-size: 13.5px;
    color : var(--kargoroo-modal-text)
}
.AddItemModal {
    backdrop-filter: blur(3px);

    --min-width: 80%;
    --max-width: 800px;
    --min-height: 80%;
    
    &::part(background) {
        border-radius: 20px;
    }

    &::part(handle) {
        background: var(--mrq-green-light);
        width: 100px;
        height: 6px;
    }

    &::part(content) {
        border-radius: 0px;
        box-shadow: 0px 0px 20px 5px rgba(5, 5, 5, 0.32);
        background-color: white;
    }

    ion-content {
        --background: var(--kargoroo-card-color);
        --keyboard-offset: 0px !important; 
    }

    .addItemContent {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 16px;
        font-family: 'Montserrat-Regular';
        align-items: center;

        


        .closeBtn {
            position: absolute;
            top: 6px;
            right: 8px;
            font-size: 32px;
            color: rgb(150, 150, 150);
        }


        .addItemTitle {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            p {            
                margin: 18px 0 8px 0;    
                font-size: 20px;
                font-weight: bold;
                color: var(--kargoroo-modal-text);
            }
        }

        ::-webkit-scrollbar {
            display: block;
            width: 18px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 20px;
            border: 7px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 20px;
            border: 7px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }
        

        .inputs-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            // overflow-y: auto; 

            overflow-y: auto;

        &::-webkit-scrollbar {
            display: block;
            width: 14px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #e7e7e7;
            border-radius: 20px;
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(190, 190, 190);
            border-radius: 20px;
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }
                       
            
            
            .inputs {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
            }

            input, textarea {
                font-size: 16px;
                border: 1px rgb(214, 214, 214) solid;
                border-radius: 8px;
                padding: 8px;
                width: 100%;
                height: 40px;
                color: rgb(43, 43, 43);
                background-color: rgb(237, 240, 245);

                &.error {
                    border: 1.5px rgb(253, 141, 141) solid;
                }
            }

            textarea {
                height: 100px;
            }

            .inputLine {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                ion-icon {
                    position: relative;
                    font-size: 25px;
                    color: var(--mrq-green-dark);
                    bottom: 4px;
                }
            }

            .input-label {
                margin: 0;
                font-size: 14px;
                color: var(--kargoroo-add-item-modal-text);
                font-weight: bold;
                margin-bottom: 8px;

                span {
                    color: red;
                }

                .errorMsg {
                    font-size: 13px;
                }
            }   
            
            .input-item {
                margin-top: 16px;
                margin-left: 10px;
                margin-right: 10px;
            }

            .multi-input-row {
                display: flex;
                flex-direction: row;
                flex: 1 1 auto;

                .input-item {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }

                .input-item:first-child {
                    margin-right: 8px;
                    width: 110px;
                }
            }
        }
        
        .upload-img-bar {
            position: relative;
            background-color: rgb(237, 240, 245);
            border: 1px rgb(214, 214, 214) solid;
            display: flex;
            flex-direction: row;
            border-radius: 8px;
            height: 74px;
            align-items: center;

            .list-of-images {
                display: flex;
                overflow-x: auto;
                flex-direction: row;
                height: 100%;
                padding-right: 64px;

                &::-webkit-scrollbar {
                    display: block;
                    height: 4px;
                }    
    
                .upload-img {
                    position: relative;
                    height: 100%;
                    display: flex;
                    flex-shrink: 0;
                    margin-right: 4px;
    
                    img {
                        height: 100%;
                        width: 86px;
                        object-fit: cover;
                        padding: 4px;
                        border-radius: 8px;
                    }

                    .removeImg {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 20px;
                        width: 20px;
                        right: -3px;
                        top: 2px;
                        font-size: 16px;
                        color: white;
                        background-color: var(--mrq-red);
                        cursor: pointer;
                        border-radius: 50px;
                    }
                }        
            }               

            .uploadBtn {
                display: flex;
                width: 40px;
                height: 40px;
                background-color: var(--mrq-green);
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                
                position: absolute;
                right: 8px;

                ion-icon {
                    font-size: 24px;
                    font-weight: bold;
                    color: white;

                }

                &:active {
                    background-color: var(--mrq-green-dark);
                }
            }
        }   
        
        .btnContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;            
        }

        .addItemBtn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-radius: 9px;
            font-family: 'Poppins';
            color: var(--add-item-btn);
            background: var(--mrq-button-main-background);
            width: 180px;
            margin: 16px 4px 24px 4px;
            border-radius: 50px;
            align-self: center;

            &.remove {
                background: var(--mrq-button-main-background-danger);
            }

            
            ion-icon {
                position: relative;
                left: 4px;
                bottom: 1px;
                font-size: 18px;
                --ionicon-stroke-width: 50px;
            }

            &:active {
                background: var(--mrq-button-main-background-active)
            }

            p {
                margin: 12px;
                font-size: 14px;
                font-family: 'Poppins';
                font-weight: bold;
            }
        }

    }
}

.estimatedCostPopoverInfo {
    --offset-x: -16px;
    --backdrop-opacity: 25%;

    p {
        padding: 0px 12px;
        font-size: 13.5px;
        font-family: 'Poppins';
        color: var(--kargoroo-modal-text);
    }
}