.ReportProblemModal {
    backdrop-filter: blur(3px);

    --min-width: 80%;
    --max-width: 800px;
    --min-height: 35%;
    --height: 45%;
    --width: 100%;
    
    &::part(background) {
        border-radius: 20px;
    }

    &::part(handle) {
        background: var(--mrq-green-light);
        width: 100px;
        height: 6px;
    }

    &::part(content) {
        border-radius: 0px;
        box-shadow: 0px 0px 20px 5px rgba(5, 5, 5, 0.32);
        background-color: white;
    }

    ion-content {
        --background: var(--pick-item-modal-background);
    }

    .ReportProblemModalContent {

        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        font-family: 'Poppins';

        .header {
            height: 50px;
            margin-bottom: 4px;


            .closeBtn {
                position: absolute;
                top: 6px;
                right: 8px;
                font-size: 32px;
                color: rgb(150, 150, 150);
            }

            .title {
                margin-top: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
    
                p {            
                    margin: 18px 0 8px 0;    
                    font-size: 20px;
                    font-weight: bold;
                    color: var(--kargoroo-text);
                }
            }
        }
    
        .main {
            display: flex;
            flex-direction: column;
            height: 100px;
            width: 100%;
            flex-grow: 1;

            overflow-y: auto;

            .description {
                text-align: center;
                padding: 12px 32px;
                color: var(--kargoroo-text);
                font-size: 15px;
            }

            .inputsContainer {
                padding: 0px 32px 12px 32px;


                .inputSection {
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    .question {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-end;
                        color: var(--mrq-grey-dark);
                        font-weight: bold;
                        font-size: 16px;

                        p {
                            margin: 0;
                        }

                        .subInfo {
                            font-size: 13px;
                            margin-left: 2px;
                        }

                    }

                }

            }
            

            
        }
    
        .footer {
            display: flex;
            // position: absolute;
            bottom: 12px;
            height: 50px;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-bottom: 18px;

            .reportBtn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background:var(--mrq-button-main-background);
                color: var(--continue-button-color);
                border-radius: 50px;
                font-weight: bold;
                width: 70%;
                max-width: 350px;
                height: 50px;


                &:active {
                    background-color: var(--mrq-green-dark);
                }

                ion-spinner {
                    color: black;
                    height: 50px;
                }

                &.loading {
                    background-color: #cfcfcf;
                }
            }
        }
    }  
}

.reportProblemForm
{
    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 20px 12px 20px;
        border-radius: 50px;
        color: var(--continue-button-color);
        background: var(--mrq-button-main-background);
        font-weight: bold;
        font-size: 14px;
        height: 48px;
        width: 160px;
        margin: 0 auto;
    }
    .item{
        padding-bottom: 4%;
    }
    .item-sku{
        padding-bottom: 4%;
    }
    .input-label {
        margin: 0;
        font-size: 14px;
        color: var(--kargoroo-add-item-modal-text);
        font-weight: bold;
        margin-bottom: 8px;

        span {
            color: red;
        }

        .errorMsg {
            font-size: 13px;
        }
    }
    .upload-img-bar {
        position: relative;
        background-color: rgb(237, 240, 245);
        border: 1px rgb(214, 214, 214) solid;
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        height: 74px;
        align-items: center;

        .list-of-images {
            display: flex;
            overflow-x: auto;
            flex-direction: row;
            height: 100%;
            padding-right: 64px;

            &::-webkit-scrollbar {
                display: block;
                height: 4px;
            }    

            .upload-img {
                position: relative;
                height: 100%;
                display: flex;
                flex-shrink: 0;
                margin-right: 4px;

                img {
                    height: 100%;
                    width: 86px;
                    object-fit: cover;
                    padding: 4px;
                    border-radius: 8px;
                }

                .removeImg {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20px;
                    width: 20px;
                    right: -3px;
                    top: 2px;
                    font-size: 16px;
                    color: white;
                    background-color: var(--mrq-red);
                    cursor: pointer;
                    border-radius: 50px;
                }
            }        
        }               

        .uploadBtn {
            display: flex;
            width: 40px;
            height: 40px;
            background-color: var(--mrq-green);
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            
            position: absolute;
            right: 8px;

            ion-icon {
                font-size: 24px;
                font-weight: bold;
                color: white;

            }

            &:active {
                background-color: var(--mrq-green-dark);
            }
        }
    }   
    .form-pading{
        padding: 20px;
        padding-top: 40px;
    }
    // h1 {
    //     border-bottom: 1px solid white;
    //     color: #3d3d3d;
    //     font-family: sans-serif;
    //     font-size: 20px;
    //     font-weight: 600;
    //     line-height: 24px;
    //     padding: 10px;
    //     text-align: center;
    //   }
     
    //   form {
    //     background: white;
    //     border: 1px solid #dedede;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-around;
    //     margin: 0 auto;
    //     max-width: 500px;
    //     padding: 30px 50px;
    //   }
     
    //   input {
    //     border: 1px solid #d9d9d9;
    //     border-radius: 4px;
    //     box-sizing: border-box;
    //     padding: 10px;
    //     width: 100%;
    //   }
     
    //   label {
    //     color: #3d3d3d;
    //     display: block;
    //     font-family: sans-serif;
    //     font-size: 14px;
    //     font-weight: 500;
    //     margin-bottom: 5px;
    //   }
     
    //   .error {
    //     color: red;
    //     font-family: sans-serif;
    //     font-size: 12px;
    //     height: 30px;
    //   }
     
    //   .submitButton {
    //     background-color: #6976d9;
    //     color: white;
    //     font-family: sans-serif;
    //     font-size: 14px;
    //     margin: 20px 0px;
    //   }
}

