.panToCurrentLocation {
  cursor: pointer;
  ion-content {
    --overflow: hidden;
  }

  z-index: 7;
  text-align: center;
  margin: 18px 8px 0px 12px;
  padding-top: 6px;
  position: fixed;
  top: 15%;
  right: 0px;
  background-color: var(--kargoroo-background-color,#00bd00);
  width: 44px;
  height: 44px;
  border-radius: 25px;
  text-align: center;

  ion-icon {
    position: relative;
    top: 2px;
    left: 0.5px;
    font-size: 28px;
    color: white;
    --ionicon-stroke-width: 38px;
  }
}
.arriving{
  z-index: 5000;
  position: absolute;
  bottom: 2%;
  right: 2%;
  background-color: black;
  color: white;
  font-family: 'Montserrat-Regular';
  .arrivingtext{
    font-size: 13px;
    margin: 5px;
  }
  .arrivetimekm{
    font-weight: bold;
  }
  .arrivingtextcenter{
    text-align: center;
  }
}
