.MyRooQuestInfoModal {
  .slide-img{
    height: 100%;
    cursor: pointer;
  
  }
  ion-slides {
    --bullet-background: #545b61;
    --bullet-background-active: var(--kargoroo-background-color);
}
  .modalContent {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 100%;
    scrollbar-width: none; /* Firefox */
    // height: 100vh;
    // background-image: linear-gradient(rgb(76, 190, 72), rgb(23, 139, 0));
    background-color: rgb(240, 240, 240);
    @media only screen and (min-width: 280px) and (max-width: 320px) {
      .remove-text-btn{
        display: none;
      }
    }
    .reqtext{
      margin-left: -20px;
    }
    ion-item{
      --background: var(--kargoroo-my-req-del-bg);
    }
    
    .infoContent {
      text-align: center;
      background-color: var(--kargoroo-my-req-del-bg);
      flex-grow: 1;
      margin: 8px 8px 4px 8px;
      border: 4px #084400 solid;
      border-radius: 25px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2),
        0 4px 12px 0 rgba(0, 0, 0, 0.2);
      overflow: hidden;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      .rqImg {
        padding: 0px;
        margin: 0px;
        height: 25vh;
        background: linear-gradient(rgb(238, 238, 238), rgb(243, 243, 243));
        border-radius: 25px 25px 0 0;
        border-bottom: solid rgb(151, 151, 151) 0.5px;
        img {
          height: 100%;
        }
      }

      .rqTitleContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: 'Montserrat-Regular';
        padding: 12px 16px 8px 16px;

        .title {
          text-align: left;

          h1 {
            margin: 0px;
            font-size: 24px;
            font-weight: bold;
            flex-wrap: wrap;
            color: var(--kargoroo-my-req-del-text-header);
          }
        }

        .costContainer {
          display: flex;
          flex-direction: column;
          margin: 0 0 0 12px;
          font-size: 13px;
          font-weight: bold;
          color: var(--kargoroo-my-req-del-text);
          text-align: right;
          min-width: 102px;

          span {
            color: var(--kargoroo-rooquest-info-text-color) 
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }

      .rqOtherInfo {
        font-family: 'Montserrat-Regular';
        padding: 8px 16px 16px 16px;
        margin: 0px;
        text-align: left;

        .description {
          padding: 8px 0 8px 0;

          h5 {
            padding: 0px;
            margin: 0px;
            font-size: 16px;
            font-weight: bold;
            color: var(--kargoroo-rooquest-info-text-color);
          }
          p {
            padding: 4px 0 0 0;
            margin: 0px;
            font-size: 16px;
            font-weight: bold;
            color: var(--kargoroo-my-req-del-text);
          }
        }
      }
    }
    .trackactionContent{
      margin: 4px 8px 8px 8px;
      border: none !important;
      border-radius: 0px !important;
      box-shadow: none !important;
    }
    .actionContent {
      background-color:var(--kargoroo-action-content-bg);
      color: var(--kargoroo-action-content-text);
      margin: 4px 8px 8px 8px;
      border: 4px #00bd00 solid;
      border-radius: 25px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2),
        0 4px 12px 0 rgba(0, 0, 0, 0.2);
      font-family: 'Montserrat-Regular';

      .statusRow {
        width: 100%;
        text-align: center;

        p {
          padding: 0;
          margin: 8px;

          span {
            color: rgb(102, 102, 102);
            padding-right: 2px;
          }
          font-weight: bold;
          font-size: 15px;

          ion-icon {
            padding: 0;
            margin: 0;
            position: relative;
            left: 1px;
            top: 4px;
            font-size: 20px;
            color: #00bd00;
          }
        }
      }

      .actionRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px;
        padding: 0 8px 0 8px;

        ion-icon {
          font-size: 24px;
        }

        ion-fab-button {
          height: 48px;
          width: 48px;
          --border-color: #084400;
          //   --border-radius: 15px;
          --border-style: solid;
          --border-width: 2px;
        }

        .leftActionBtn {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 8px;

          p {
            text-align: left;
            padding: 0 0 0 6px;
            margin: 0;
            font-size: 14px;
          }
        }

        .rightActionBtn {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 8px;

          p {
            text-align: right;
            padding: 0 6px 0 0;
            margin: 0;
            font-size: 14px;
          }
        }
      }
    }
  }
}
