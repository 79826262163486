.filterModal {
    font-family: 'Montserrat-Regular';
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0 8% 100px 8%;
    color: var(--kargoroo-modal-text);
  
    &::part(content) {
      --border-color: #044800;
      --border-style: solid;
      --border-width: 4px;
      --border-radius: 25px;
      height: 350px;
  
      ion-page {
        color: red;
      }
    }
  
    &::part(backdrop) {
      --backdrop-opacity: 0.6;
    }
  
    .inputElements {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
  
      input {
        text-decoration: none;
        text-align: center;
        font-weight: bold;
        outline: none;
        border: none;
        color: var(--mrq-green);
        font-size: 24px;
        width: 20px;
        border-bottom: 2px green solid;
        border:  2px green solid;
        border-color: var(--mrq-green);
        margin-left: auto;
        margin-right: auto;
  
  
      }
    }
  
    .actionButtons {
      position: absolute;
      bottom: 0px;
      width: 100%;
      .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 2px 8px 4px 8px;
  
        ion-button {
          --border-color: #044800;
          --border-style: solid;
          --border-width: 2px;
          --border-radius: 15px;
          flex-grow: 1;
        }
      }
    }

    // .mapPosition{
    //   border : 2px solid red;
    //   margin-top: 1rem;
    //   // height : 300px
    // }

    // .modalContent { 
    //   display: flex;
    //   flex-direction: column;
    //   border : 3px solid blue
    // }
  }