.modal{


    .container-test{
        height: 100%;
        display: flex;
        flex-direction: column-reverse;

    }
    .footer{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        .makeOfferBtn{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: var(--mrq-green);
            color: white;
            border-radius: 50px;
            font-weight: bold;
            width: 70%;
            max-width: 350px;
            height: 50px;

            &:active{
                background-color: var(--mrq-green-dark);
            }

            ion-spinner{
                color: black;
                height: 50px;
            }

            &.loading{
                background-color: #cfcfcf;
            }
        }
    }

    .searchbar{
        position: absolute;
        // border:1px solid red;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top:2px;

        input {
            width:100%;
            color:#777;
            border-radius: 5px;
            height:44px;
        }

        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #ababab;
            opacity: 1; /* Firefox */
          }
          
        //   :-ms-input-placeholder { /* Internet Explorer 10-11 */
        //     color: red;
        //   }
          
        //   ::-ms-input-placeholder { /* Microsoft Edge */
        //     color: red;
        //   }

        .dropoff{
            margin-top: 0.25rem;
        }
    }
}

.AutoCompleteWithMap {

    position: relative;
    
    .controlButtonDiv{

        background: #fff;
        border: 0;
        box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    
        border-radius: 50%;
        display: block;
        width: 29px;
        height: 29px;
        overflow: hidden;
        cursor: pointer;
        -webkit-transition: background-color .16s ease-out;
        transition: background-color .16s ease-out;

        &:hover {
            background: none padding-box rgb(235, 235, 235);
        }
    }
    .google-autocomplete-container {
        // border:1px solid red;
        width: 100%;
        padding: 0 4px;
        z-index: 999999;
        position: absolute;
        left: 0;
        right: 0;
        margin-top: 12px;
        

        .inputContainer {
             .pickup, .dropoff{
                font-size: 14px;
                border: 1px var(--input-container-border-color) solid;
                border-radius: 80px;
                width: 100%;
                height: 42px;
                background-color: var(--input-container-dark-background);
                margin-top: 0px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
             }

             .dropoff{
                margin-top: 0.25rem;
             }

            
            

            input {

                color: var(--input-color);
                overflow-x: scroll;
                padding: 8px 12px;
                // height: 40px;
                background-color: var(--input-background-color);
                // background-color: red;
                text-decoration: none;
                border: none;
                flex: 1 1 auto;
                border-radius: 80px;                 
                height:25px;     

                &:focus {
                    outline: none;
                }
            }

            span {
                padding: 6px;
                display: flex;
                font-size: 20px;
                color: var(--clear-button-color);

                &:active {
                    color: rgb(0, 199, 33);
                }
            }
        }        
    }

    .google-map-confirm-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 12px;
        // background-color: red;
        // height: 500px;
        border-radius: 8px;

        &.hasMap {
            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        }

        overflow: hidden;



        #google-map-pickup-confirm {
            width: 100%;
            border-radius: 8px;
            height: 100%;
            min-height: 150px;

            .google-map-container{
                width: 100%;
                height: 390px;
            }
        }

        .address {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: --var(mrq-main-background);       
            font-size: 12px;
            padding: 6px;

            .name {
                font-size: 13px;
                font-weight: bold;
            }
        }
    }

    
    .gm-ui{
        color: red !important;
    }
    .gm-style .gm-style-iw-c {
        display: flex;

        h3 p{
            text-align: center;
            margin: 0 0 10px;

        }
        h3{
            font-size: 18px;
            line-height: 22px;
            text-decoration: none !important;
            padding: 0;
            border: 0;
            font-family: "Muli", sans-serif;
        }
        p{
            font-size: 13px;
        }
        
    .info-window-content {
        align-items: center;
        justify-content: center;
        border:2px solid blue;
    }
        // button{
        //     background: none;
        //     display: block;
        //     border: 0px;
        //     margin: 0px;
        //     padding: 0px;
        //     text-transform: none;
        //     appearance: none;
        //     position: absolute;
        //     cursor: pointer;
        //     user-select: none;
        //     top: -6px;
        //     right: -6px;
        //     width: 30px;
        //     height: 30px;
        //     opacity: 0.6;
        // }
        
    }
    
}