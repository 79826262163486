.cartCheckout {

    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: end;
    align-items: center;
    align-content: center;
    margin: 1rem;
}

.cartFooter {

    border-top: 2px solid rgb(200, 200, 200);
    background-color: white;
}

.cartCheckout ion-card-subtitle {

    font-size: 1.3rem;
}

.cartItem ion-avatar {

    height: 4rem;
    width: 4rem;
}

.cartSlider:not(:nth-child(1)) {

    border-top: 2px solid rgb(236, 236, 236);
}

.cartActions {

    display: flex;
    flex-direction: column;
}