.deliveryOffer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
    border-bottom: 1px solid #e6e6e6;

    &:last-child {
        border-bottom: none;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .estimatedDelivery {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px 4px;
            
            .title {
                font-size: 13px;
                font-weight: bold;
                color: var(--mrq-add-items-secondary-text);
            }
            .value {
                font-size: 18px;
                font-weight: bold;
                color: var(--mrq-green);

                &.offerAmount {
                    font-size: 16px;
                    font-weight: bold;
                    color: var(--mrq-green);

                    ion-icon {
                        font-size: 13px;
                        font-weight: normal;
                        color: var(--mrq-add-items-secondary-text);
                    }
                }
            }
        }

        .offerExpiry {           
            display: flex;
            flex-direction: column;
            align-items: flex-start;      
            padding: 0px 4px;

            p {
                font-size: 13px;
                color: var(--mrq-add-items-secondary-text);
            }

            .expiryspan {
                font-weight: bold;

                &.warning {
                    color: var(--mrq-red);
                }   
                
                &.success {
                    color: rgb(63, 121, 245);
                }       
            }
            
        }

    }

    
    .offerActions {
        display: flex;
        flex-direction: row;

        .offerAction {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 8px;                                

            p {
                font-size: 13px;
            }                               

            .circle {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
                border-radius: 50px;

                ion-icon {
                    font-size: 28px;
                }
            }


            &.accept {
                .circle {
                    &:active {
                        background-color: var(--mrq-green);
                        color: white;
                    }
                    border: 1px solid var(--mrq-green);
                }
                color: var(--mrq-green);
            }

            &.reject {
                .circle {
                    &:active {
                        background-color: var(--mrq-red);
                        color: white;
                    }
                    border: 1px solid var(--mrq-red);
                }
                color: var(--mrq-red);
            }

        }

    }   

}