.peekingModal::part(content) {
    min-height: 200px;
    width: 95%;
    height: 75%;
    position: absolute;
    bottom: 0px;
    background: var(--kargoroo-address-modal-bg);
    border-radius: 15px 15px 0 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2),
        0 4px 12px 0 rgba(0, 0, 0, 0.2);

  .modalContent {
    text-align: center;
    .titleContainer {
      text-align: center;
      ion-icon{
        cursor: pointer;
      }
      .peekingModalTitle {
        color: var(--kargoroo-address-header-text);
        font-family: 'Montserrat-Regular';
        font-weight: 600;
        font-size: 20px;
        margin: 0;
        position: relative;
        bottom: 4px;
      }

      .peekingModalChevron {
        text-align: center;
        color: #00bd00;
        font-size: 40px;
      }
    }

    .peekingModalTitle {
      align-self: center;
      color: white;
      font-family: 'Montserrat-Regular';
      font-weight: 600;
      font-size: 20px;
    }

    ion-label {
      font-family: 'Montserrat-Regular';
    }

    .inputGroup {
      margin: 20px 12px 0px 12px;
      padding: 10px;
      background-color: var(--kargoroo-single-item-quest-bg);
      border-radius: 20px;

      .addressNameInput {
        padding-right: 12px;
      }
    }
    ion-item {
      --ion-item-border-color: var(--kargoroo-ion-bottom-broder-color);  
    }

    .saveAddressBtn {
      font-family: 'Montserrat-Regular';

      margin: 12px 12px 0px 12px;
      --border-radius: 12px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .peekingModal::part(content) {
    width: 600px;
    .saveAddressBtn{
      width: 150px;
      margin: 0 auto !important;
      margin-top: 12px !important;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .peekingModal::part(content) {
    width: 600px;
    .saveAddressBtn{
      width: 150px;
      margin: 0 auto !important;
      margin-top: 12px !important;
    }
  }
}

@media screen and (min-width: 1601px) {
  .peekingModal::part(content) {
    width: 600px;
    .saveAddressBtn{
      width: 150px;
      margin: 0 auto !important;
      margin-top: 12px !important;
    }
  }
}
